#why_us_content {

	display: flex;
	justify-content: space-between;
	max-width: 1560px;
	width: 100%;
	margin: 0 auto 60px;
}

.article_wrapper {

	display: flex;
	flex-direction: column;
	align-self: center;
	max-width: 720px;
	width: 100%;
}

#where_we_are, #where_we_are_going {

	padding-left: 20px;
	margin-left: 10px;

	h3 {
		font-size: 24px;
		font-weight: 700;
		position: relative;

		&:before {

			position: absolute;
			content: '';
			display: block;
			width: 4px;
			height: 65px;
			top: 0;
			left: -20px;
			background: $accent;
		}
	}

	h4 {
		display: none;
	}

	p {
		font-size: 18px;
		line-height: 1.8;
		margin: 0 0 50px;
	}

	a {
		color: $accent;
		transition: color .3s;

		&:hover {
			color: $primary;
		}
	}
}

#why_us_cta {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 764px;
	width: 100%;
	min-height: 657px;
	background-image: url('/dist/images/why_us_letswork_img.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin-left: 35px;

	h4 {
		font-size: 60px;
		font-weight: 700;
		color: $grey;
	}

	span {
		display: block;
		color: $white;
		font-size: 22px;
		font-weight: 300;
		margin: 0 0 35px;
	}

	.btn {

		border-radius: 500px;
		border: 1px solid $white;
		color: $white;
		padding: 20px 65px;
		box-sizing: border-box;
	}
}

#why_us_mobile_contact {
	display: none;
}

@media screen and (max-width: 1100px) {

	#why_us_content {

		flex-direction: column;
		align-items: flex-start;
	}

	.article_wrapper {
		max-width: 100%;
	}

	#where_we_are, #where_we_are_going {

		padding: 0 35px;
		box-sizing: border-box;

		h4 {
			display: block;
			font-size: 14px;
			font-weight: 700;
			margin: 10px 0 0 0;
		}
	}

	#why_us_cta {

		margin: 0 auto;
		min-height: 450px;
		padding: 0 35px;
		box-sizing: border-box;
		text-align: center;
	}
}

@media screen and (max-width: 750px) {

	#where_we_are, #where_we_are_going {

		h3 {
			font-size: 20px;
		}

		p {

			font-size: 16px;
		}
	}
}

@media screen and (max-width: 500px) {

	#why_us_content {
		margin: 0 auto;
	}

	#why_us_mobile_contact {
		display: block;
		text-align: center;

		p {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.btn {
			margin: 0 auto;
			max-width: 300px;
			width: 100%;
		}
	}
}