#websites {

	/* stylelint-disable */
	#internal_main.why_us {

		h3, h2.tagline_bullets {
			white-space: nowrap;

			li {
				list-style: disc;
				margin-left: 20px;
			}
		}
	}
	/*stylelint-enable */
}

#web_styles {
	max-width: 1560px;
	margin: 80px auto 40px;
	width: 100%;
	display: flex;

	.select_style {
		width: 50%;
		padding: 0 10px;

		h4, h3.type_description {
			margin: 25px 0;
			font-size: 18px;
		}

		button {
			border: none;
			background-color: $white;
			font-size: 30px;
			color: $grey;
			cursor: pointer;

			&:last-child {
				margin-left: 40px;
			}

			&.active {
				color: $black;
				font-weight: 700;
				padding-bottom: 3px;
				border-bottom: 3px solid $accent;
			}
		}
	}

	.style_info {
		display: none;
		justify-content: space-between;
		align-items: center;
		max-width: 750px;
		margin-bottom: 40px;

		&.active {
			display: flex;
		}

		h2 {
			font-size: 30px;
			font-weight: 700;
		}

		a {
			margin-right: 20px;
			white-space: nowrap;
			text-decoration: underline;
			font-size: 18px;
			color: $black;
		}
	}

	.select_ctn {
		display: none;

		h4, h3.type_description {
			max-width: 650px;
			padding-right: 10px;
			font-size: 16px;
		}

		&.active {
			display: block;
		}
	}
}

#style_selection {
	background-color: $grey_light;
	padding: 15px 25px 25px;

	p {
		margin-bottom: 20px;
	}

	ul {
		display: flex;
		justify-content: space-between;

		li {
			margin: 0 10px;
			width: 100%;
			cursor: pointer;

			img {
				width: 100%;
				max-width: 230px;
				min-height: 130px;
				object-fit: fill;
			}
		}
	}
}

#site_selection {
	border: 1px solid $grey;
	width: 50%;

	div {
		display: none;

		&.active {
			height: 100%;
			display: block;
		}

		.lrg_img_wrap {
			display: none;

			&.active {
				width: 100%;
				display: block;
				height: 100%;
				object-fit: fill;
			}
		}
	}
}

#website_features {

	max-width: 1560px;
	width: 100%;
	margin: 40px auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	.feature_item {

		img {
			margin-bottom: 15px;
		}

		p {
			text-align: center;
			font-size: 18px;
		}
	}
}

#website_cta {
	max-width: 1560px;
	margin: 40px auto;
	width: 100%;

	h3 {
		color: $grey;
		font-size: 35px;
		text-align: center;
		font-weight: 400;
		margin: 45px 10px;

		&.fourstep {
			font-size: 28px;
			color: black;
			font-weight: 700;
			text-align: left;
			margin: 0;
		}
	}

	.accordion {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.acc_item {
			border: 1px solid $grey;
			margin: 10px;
			padding: 15px;
			display: flex;
			align-items: center;
		}
	}

	h2 {
		font-size: 45px;
		color: $grey;
		margin-right: 25px;
	}

	h4 {
		font-size: 28px;
		color: black;
		font-weight: 700;
	}
}

@media screen and (max-width: 1020px) {

	#website_cta {

		h3 {
			font-size: 30px;
		}

		h2 {
			font-size: 40px;
			margin-right: 15px;
		}

		h4, h3.fourstep {
			font-size: 25px;
		}
	}

	#style_selection {
		padding: 15px 10px;
	}

	#web_styles {
		margin: 0;
	}
}

@media screen and (max-width: 950px) {

	#web_styles {
		flex-direction: column;
		justify-content: center;

		.select_style {
			width: 90%;
			margin: 0 auto 25px;

			h4 {
				margin: 0 0 15px;
			}
		}

		.select_ctn {

			h4 {
				margin: 15px 0;
			}
		}
	}

	#site_selection {
		width: 90%;
		margin: 0 auto;

		div {

			.lrg_img_wrap.active {
				object-fit: contain;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#websites {

		/* stylelint-disable */
		#internal_wrap div {
			width: 100%;
		}
		/* stylelint-enable */
	}

	#website_features {

		flex-wrap: wrap;

		.feature_item {

			display: flex;
			flex-direction: column;
			width: calc(50% - 40px);
			margin: 20px;
		}
	}

	#website_cta {

		.accordion {
			display: flex;
			flex-direction: column;

			.acc_item {
				padding: 10px 15px;
			}
		}

		h3 {
			margin: 25px 10px;
		}

		h2 {
			font-size: 30px;
			margin-right: 10px;
		}

		h4, h3.fourstep {
			font-size: 20px;
		}
	}

	#web_styles {

		.select_style {

			button {
				font-size: 25px;
			}
		}

		.style_info {

			h2 {
				font-size: 25px;
			}
		}
	}
}

@media screen and (max-width: 550px) {

	#websites {

		/* stylelint-disable */
		#internal_wrap .left {
			text-align: center;
		}

		#internal_wrap div {
			width: auto;
		}

		#internal_main.why_us {

			hgroup {
				padding: 0;
			}

			h3, h2.tagline_bullets {
				font-size: 12px;

				ul {
					display: flex;
					justify-content: center;
	
					li {
						list-style: none;
	
						&:last-child {
							list-style: disc;
						}
					}
				}
			}
		}
		/* stylelint-enable */
	}

	#website_cta {

		h4 {
			font-size: 18px;
		}
	}

	#style_selection {

		ul li:last-child {
			display: none;
		}
	}
}