/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Josefin Sans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQbMZhLw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$body: 'Poppins', sans-serif;
$footer_font: 'Josefin Sans', sans-serif;
$header_font: 'Josefin Sans', sans-serif;

/*********************
* Colors
*********************/
$primary: #264a5d;
$primary_dark: #04131c;
$accent: #008766;
$grey: #b3b3b3;
$grey_light: #e6e6e6;
$black: #000000;
$white: #fff;

/*********************
* Reusable Styles
*********************/

body {
	background-color: #000;
	position: relative;
	min-height: 100%;

	font: {
		family: $body;
		size: 16px;
	}

	line-height: 1.3;
}

main {
	flex-grow: 1;
}

/*** ID ***/

#skip_nav {
	position: fixed;
	top: -9999px;
	left: -9999px;
}

#container {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	min-height: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;

	a {
		text-decoration: none;

		&:hover {
			color: $accent;
		}
	}
}

#cta_banner {
	background: #052460;

	h2 {
		font-size: 26px;
		color: #fff;
		padding-right: 30px;

		span {
			font-weight: 700;
		}
	}

	a {
		margin-left: 30px;
	}

	.cta_wrap {
		padding: 25px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media screen and (max-width: 540px) {

		&.mobile {

			h2 {
				max-width: 208px;

				span {
					margin-top: 0;
				}
			}

			h3 {
				line-height: 1.5;
			}
		}

		&.perf {

			h2 {
				padding: 0;
				font-size: 38px;
				max-width: none;
				display: block;

				span {
					margin-top: 0;
					font-size: 38px;
				}
			}

			h3 {
				line-height: 1.5;
			}
		}

		h2 {
			display: flex;
			flex-direction: column;
			max-width: 257px;
			font-size: 20px;

			span {
				font-size: 39px;
				margin-top: 15px;
			}
		}

		.cta_wrap {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 40px 35px;

			a {
				margin-top: 20px;
				margin-left: 0;
			}
		}
	}
}

/*** Classes ***/

.title {
	font-size: 48px;
	color: $grey;
	font-weight: 700;

	@media screen and (max-width: 750px) {
		font-size: 25px;
	}
}

.subtitle {
	color: $black;
	font-weight: 700;
	font-size: 24px;

	&.light {
		color: $white;
	}
}

.btn {
	position: relative;
	display: block;
	max-width: 320px;
	padding: 12px 18px;
	text-align: center;
	text-decoration: none;
	transition: color .25s, border .25s;
	cursor: pointer;
	color: #fff;
	font-weight: 700;
	font-size: 16px;
	background: $accent;
	border-radius: 8px;
	border: 1px solid $accent;
	white-space: nowrap;

	&:hover {
		background: transparent;
		color: $accent;
	}
}
