/* stylelint-disable */
.popup_vacate,
.popup_application,
.popup_family,
.popup_asp,
.popup_adv,
.popup_ads,
.popup_learn {
	#popup_bg_close {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_family {
	#popup_family {
		pointer-events: auto;
		opacity: 1;
	}
}


.popup_learn {
	#popup_learn {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_ads {
	#popup_ads {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_asp {
	#popup_asp {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_adv {
	#popup_adv {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_vacate {
	#popup_vacate {
		pointer-events: auto;
		opacity: 1;
	}
}

.popup_application {
	#popup_application {
		pointer-events: auto;
		opacity: 1;
	}
}

#popup_bg_close {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, .5);
	z-index: 22;
	cursor: pointer;
	pointer-events: none;
	opacity: 0;
	transition: opacity ease .35s;
}

#popup_learn {

	.bottom_links {
		position: absolute;
		top: 15px;
		right: 50px;
		border: none;

		.close_btn {

			img {
				width: 45px;
				height: 45px;
			}
		}
	}
}

#learn_form {
	padding: 75px 30px;

	.form_inputs {
		display: flex;
		flex-direction: column;

		.response_message {
			color: #ff0000; 
		}

		p {
			margin-bottom: 0;
		}

		
	}

	.legality {
		color: #8D8D8D;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal; 
		display: flex;
		flex-direction: column;
		margin-top: 85px;

		a {
			color: #8D8D8D;
			text-decoration: underline;

			&:hover {
				color: $accent;
			}
		}
	}

	.btn {
		background: $accent;
		border-radius: 8px;
		box-shadow: 4px 4px 4px 0px rgba(5, 36, 96, .17);
		color: #FFF;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase; 
		width: 100%;
		padding: 15px 20px;
	}

	label {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}

	input {
		box-sizing: border-box;
		width: auto;
		padding: 16px 0;
		margin: 0 0 30px;
		border-bottom: 1px solid #bcbec0;
		border-top: none;
		border-left: none;
		border-right: none;
		font-size: 16px;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: $primary;
		}
	}
}

.popup_wrap {
	max-width: 535px;
	padding-bottom: 45px;
	height: 100%;
	flex-grow: 1;

	&.wide {
		max-width: none;
		display: flex;
		padding-bottom: 0;

		.side_cta {
			background: rgba(242, 244, 248, 1);
			width: 40%;
			display: flex;
			align-items: center;
			padding: 48px 32px;

			.cta_inner {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 500px;
				margin: auto 0;
				box-shadow: 4px 14px 31.7px 0px rgba(5, 36, 96, .21);
				padding: 0 20px;
				background: radial-gradient(52.09% 59.93% at 104.82% 61.07%, rgba(13, 132, 103, 0.14) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0) 100%);
				background-color: #fff;
				position: relative;

				.jumbo_lines {
					position: absolute;
					right: 0;
					bottom: 0;
					background: url("/dist/images/icons/SVG/home_line_top_right.svg");
						background-position-x: 0%;
						background-position-y: 0%;
						background-repeat: repeat;
					background-repeat: no-repeat;
					background-position: left;
					width: 100%;
					height: 100%;
					z-index: 0;
					opacity: .3;
				}

				.title {
					color: $primary;
					line-height: 1.1;
					font-size: 38px;
					padding-top: 45px;
				}
			}
		}

		.author {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding-bottom: 45px;

			span {
				color: #1a1c2b;
				font-size: 14px;
			}
		}

		.form_wrap {
			width: 60%;
		}
	}
}

.bottom_links {
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
	border-top: 1px solid #bcbec0;

	.close_btn {
		display: flex;
		align-items: center;
		gap: 8px;
		color: $accent;
		text-decoration: underline;
		font-weight: 700;
		cursor: pointer;

		img {
			width: 15px;
			height: 15px;
		}
	}

	a {
		color: $accent;
		text-decoration: underline !important;
		font-weight: 700;
	}
}

.item_wrap {
	position: relative;
	padding: 50px 30px 10px;
	display: flex;
	flex-direction: column;
	min-height: 600px;

	&.pad {
		padding: 0;
	}
}

.template_img {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	img {
		width: 100%;
		height: 100%
	}
}

.popup_item {
	color: #1a1c2b;
	line-height: 1.4em;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	max-width: 1100px;
	min-width: 300px;
	max-height: 90vh;
	overflow-y: auto;
	border-radius: 12px;
	transform: translate(-50%, -50%);
	z-index: 23;
	pointer-events: none;
	opacity: 0;
	transition: opacity ease .35s;
	border: 1px solid #ededed;
	background: #fff;
	box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);

	&.square {
		border-radius: 0;
	}

	li {
		list-style-type: disc;
		margin-left: 20px;
	}

	.popup_close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 25px;
		height: 7px;
		background: #fff;
		transform: rotate(45deg);
		cursor: pointer;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			transform: rotate(90deg);
		}
	}

	.popup_head {
		font-size: 42px;
		margin-bottom: 20px;
		line-height: 1em;

		span {
			padding-bottom: 15px;
			display: inline-block;
			font-weight: 700;
		}
	}

	.popup_subhead {
		font-size: 25px;
		font-weight: 500;
		margin-bottom: 20px;
	}

	p {
		line-height: 1.4em;
		margin-bottom: 60px;

		&.popup_disclaimer {
			color: #ff0000;
			line-height: normal;
			margin-bottom: 20px;
			font-size: 20px;
			font-weight: 800;
			text-shadow: 1px 1px 0px #000000;
		}
	}

	.popup_form_break_head {
		text-align: left;
		margin: 10px 0 7px;
		padding-left: 20px;
	}

	.btn {
		margin-top: 20px;
		border: none;
		background: #fbc439;
		color: #131313;
		font-weight: 600;
		letter-spacing: 1.8px;
		font-size: 18px;
		padding: 10px 20px;
	}
}

// Media Queries
@media screen and (max-width:950px) {
	.popup_item {
		padding: 30px 15px;
		min-width: 90vw;

		&.square {
			padding: 0;
		}

		.popup_head {
			font-size: 3vw;
		}

		.template_img {
			display: none;
		}

		.popup_wrap {
			max-width: none;
		}

		p {
			margin-bottom: 30px;
		}

		.btn {
			margin-top: 20px;
		}
	}
}

@media screen and (max-width: 768px ) {

	#learn_form {
		padding: 15px 30px;

		.legality {
			margin-top: 20px;
		}

		.btn {
			margin-bottom: 20px;
		}
	}

	.popup_item {

		.popup_wrap {

			&.wide {
				flex-direction: column;

				.side_cta {
					width: auto;
					padding: 20px;

					.cta_inner {
						height: 250px;
					}
				}

				.form_wrap {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width:500px) {
	.popup_item {
		.popup_head {
			font-size: 5vw;
		}
	}

	.popup_item {

		.popup_wrap.wide {

			.side_cta {

				.cta_inner {
					height: 285px;

					.title {
						font-size: 32px;
						margin-top: 5px;
					}
				}
			}
		}
	}
}

/* stylelint-enable */
