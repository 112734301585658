#blog_post_container {

	.blog_post_content {
		max-width: 1072px;
		margin: 0 auto;
	}
}

#blog {

	.blog_container {
		max-width: 1420px;
		margin: 0 auto;
		height: 100%;
		min-height: 50vh;
	}
}

.blog_heading {
	font-weight: 700;
	font-size: 2em;
	line-height: 2em;
}

.blog_container {
	display: flex;
	flex-wrap: wrap;
	padding: 75px 15px 0;
	gap: 70px;
	justify-content: center;
}

.blog_side {
	padding: .3em;
	max-width: 660px;

	.blog_side_heading {
		font-weight: 400;
		font-size: 31px;
		line-height: 38px;
	}

	.blog_rec_items {

		li a {
			text-decoration: none;

			&:hover {

				div {

					span {
						text-decoration: underline;
					}
				}
			}

			div {

				display: flex;
				gap: 30px;

				img {
					width: 250px;
					height: 191px;
					object-fit: cover;
					border-radius: 8px;
				}

				h4 {
					font-size: 25px;
					font-weight: 600;
					padding-bottom: 10px;
				}

				span {
					font-size: 18px;
					font-weight: 500;
					display: flex;
					gap: 8px;
					align-items: flex-end;

					img {
						height: fit-content;
						width: auto;
						border-radius: 0;
					}
				}
			}
		}
	}

	a {
		text-decoration: underline;
		color: #052460;
		font-size: 25px;
	}

	li {
		margin: 1em 0 2.2em;
	}
}

.blog_posts_item {
	padding: 0;
	max-width: 658px;
	margin: 0 auto 30px;
	border: 1px solid #f5f5f5;
	border-radius: 12px;
	box-shadow: 2px 5px 6px 0px rgba(77, 77, 77, .15);

	.post_img {
		border-radius: 12px 12px 0 0;
	}

	.post_wrap {
		background: #fff;
		display: flex;
		flex-direction: column;
		z-index: 0;
		position: relative;
		padding: 15px 40px;
		border-radius: 0 0 12px 12px;
	}


	img {
		width: 100%;
		height: 100%;
		border-radius: 12px 12px 0 0;
	}

	h3 {
		color: #052460;
		font-weight: 600;
		font-size: 22px;
		padding-bottom: 8px;
	}

	.blog_post_date {
		color: #052460;
		margin-bottom: 20px;
	}

	.blog_post_content {
		margin-bottom: 20px;
		color: #333;
		min-height: 130px;
		font-size: 18px;
	}

	.btn_wrap {
		display: flex;
		gap: 8px;
		align-items: center;
		padding: 20px 0 15px;
		border-top: 1px solid #bcbec0;

		a {
			color: $primary;
			font-weight: 700;
			font-size: 20px;
			text-decoration: none;
		}

		img {
			width: fit-content;
			border-radius: 0;
		}
	}
}

.blog_post {
	padding: 0 20px;

	strong {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}

	ul,
	ol {
		padding: .5em;
		padding-left: 1.2em;
	}


	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	li {
		padding: .4em;
	}

	.blog_post_title {
		font-weight: 700;
		font-size: 1.5em;
		line-height: 1.2em;

		a {
			text-decoration: none;
		}
	}


	h2,
	h3,
	h4,
	h5 {
		font-weight: 700;
	}

	h2 {
		font-size: 1.5em;
		line-height: 1.7em;
		padding: .6em 0;
	}

	h3 {
		font-size: 1.4em;
		line-height: 1.6em;
		padding: .5em 0;
	}

	h4 {
		font-size: 1.3em;
		line-height: 1.5em;
		padding: .4em 0;
	}

	h5 {
		font-size: 1.2em;
		line-height: 1.4em;
		padding: .3em 0;
	}

	p {
		padding: .5em .2em;
		line-height: 1.4em;
		font-size: 18px;
		color: #333;

		img {
			height: 100%;
			width: 100%;
		}
	}

	.blog_post_date {
		display: block;
		line-height: 1em;
		font-style: italic;
		padding-bottom: 15px;
	}
}

.blog_pagination {
	max-width: 1420px;
	margin: 0 auto;
	padding-bottom: 100px;

	a,
	strong {
		padding: 0 1em;
		margin: 0 1em;
		font-size: 1.2em;
		text-decoration: none;
		color: $primary;
	}

	strong {
		font-weight: 700;
	}
}

@media screen and (max-width: 1285px) {

	.blog_side {
		display: none;
	}

	.blog_container {
		justify-content: center;
	}
}

@media screen and (max-width: 950px) {

	.blog_container {
		padding: 15px 15px 0;
	}

	.blog_pagination {
		padding-bottom: 55px;
	}
}

@media screen and (max-width: 768px) {

	.blog_pagination {
		padding-bottom: 40px;
	}

	.blog_posts_item {

		.post_wrap {
			padding: 15px 20px 0;
		}

		.blog_post_content {
			font-size: 16px;
			min-height: 0;
		}

		.btn_wrap {

			a {
				font-size: 18px;
			}
		}
	}
}
