/* stylelint-disable */
#print {
	background: radial-gradient(52.09% 59.93% at -4.82% 61.07%, rgba(13, 132, 103, 0.14) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0.00) 100%);;

	.internal_content {
		background: radial-gradient(36.68% 44.74% at 1.25% 20.63%, rgba(48,131,187,0.15) 0%, rgba(103,164,205,0.11) 24.1%, rgba(255,255,255,0) 100%);
	}

	#gallery_head {
		border-bottom: 1px solid #dedede;
		margin-bottom: 55px;
	}

	.print_main_img {
		display: flex;
		justify-content: center;
		margin-top: -50px;

		img {
			height: 100%;
			max-height: 765px;
		}
	}

	#print_graph {
		display: flex;
		flex-direction: column;
		align-items: center;

		.main_logo {
			width: 350px;
		}

		.top_lines {
			padding: 30px 0 10px;
		}
	}

	#google_basics {
		padding: 20px 10px;

		.basics_cards article {
			max-width: 280px;

			.dots_ctn {
				display: none;
			}
		}
	}

	#three_lines {
		width: 100%;
		max-width: 650px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	#basics_blue {
		padding: 15px 10px;

		.basics_wrap {
			display: flex;
			gap: 50px;

			article {
				border-radius: 8px;
				background: #052460;
				color: #fff;
				padding: 15px 20px;
				width: 230px;

				.dots_ctn {
					display: none;
				}

				&.white {
					background: transparent;
					color: #1a1c2b;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				h3 {
					font-weight: 700;
					font-size: 18px;
					padding: 15px 0;
				}
				
				p {
					padding-bottom: 10px;
				}
			}
		}
	}

	@media screen and (max-width: 1125px) {

		#print_graph {

			.main_logo {
				width: 280px;
			}
		}

		#basics_blue {

			.basics_wrap {
				gap: 30px;
			}
		}

		#google_basics {

			.basics_cards {
				gap: 33px;

				article {
					max-width: 230px;
				}
			}
		}
	}

	@media screen and (max-width: 950px) {

		.print_main_img {

			img {
				max-height: 455px;
			}
		}

		#three_lines {
			max-width: 482px;
		}

		#print_graph {

			.top_lines {
				max-width: 482px;
			}
		}

		#basics_blue {

			.basics_wrap {

				article {
					width: 170px;
				}
			}
		}

		#google_basics, {

			.basics_cards {

				article {
					max-width: 170px;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		#print_graph {

			.main_logo {
				width: 230px;
			}

			.top_lines {
				display: none;
			}
		}

		#google_basics {

			.basics_cards {

				article {
					max-width: 320px;
					padding: 25px 25px 45px;

					.dots_ctn {
						display: flex;
						justify-content: center;

						.dot {
							width: 15px;
							height: 15px;
							background: #d9d9d9;
							border: none;
							border-radius: 500px;
							cursor: pointer;

							&:not(:nth-of-type(1)) {
								margin-left: 15px;
							}

							&.active {
								background: #008766;
							}
						}
					}

					&:not(:nth-of-type(1)) {
						display: none;
					}
				}
			}
		}

		#basics_blue {

			.basics_wrap {
				flex-wrap: wrap;
				justify-content: center;

				article {
					width: 320px;

					&:not(:nth-of-type(1)) {
						display: none;
					}
				}
			}
		}

		#three_lines {
			display: flex;
			justify-content: center;

			img {

				&:not(:nth-of-type(even)) {
					display: none;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {

		.print_main_img {

			img {
				max-width: 390px;
			}
		}
	}
}

#google_ads {

		&.template {

		#ads_features  {

			span {
				font-weight: 700;
			}

			article {
				padding: 69px 80px;
			}

			.ads_feat_img  {
				align-items: unset;
				justify-content: unset;

				img {
					width: 100%;
				}
			}

			@media screen and (max-width: 1100px) {

				.ads_feat_img {
					padding: 0;

					img {
						max-height: 525px;
					}
				}
			}

			@media screen and (max-width: 1100px) {

				article {
					padding: 40px 30px;
				}

				p {
					padding-bottom: 25px;
				}

				h2 {
					margin-bottom: 30px;
				}
			}
		}
	}
}
