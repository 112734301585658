#careers {

	/* stylelint-disable */

	#gallery_head {
		display: flex;
		max-width: 1345px;
		align-items: end;
		justify-content: center;

		h1 {
			text-align: left;
		}

		h2 {
			font-size: 25px;
			margin-bottom: 15px;
		}

		p {
			max-width: 635px;
		}

		.btn_group {
			justify-content: flex-start;
		}

		.img_group {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.top_left {
				height: 200px;
				margin-top: auto;
			}

			.bottom {
				width: 280px;
				margin: 0 auto;
			}
			
			div {
				display: flex;
			}
		}
	}

	#cj_about {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 100px 10px;

		.cj_wrap {

			img {
				width: 100%;
				height: 100%;
				max-width: 375px;
			}
		}

		.cj_review {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-width: 575px;
			padding-left: 100px;

			p {
				font-size: 18px;
				line-height: 1.6;
				padding-bottom: 80px;
				color: #052460;
			}

			div {
				display: flex;
				flex-direction: column;

				h4 {
					font-weight: 700;
					color: #052460;
				}
				
				p {
					font-size: 15px;
					margin-bottom: 20px;
					padding: 0;
					color: #052460;
				}

				img {
					width: fit-content;
				}
			}
		}
	}

	#ads_features {

		.ads_feat_img {

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	#google_basics {

		.basics_cards {
			display: grid;
			grid-template-columns: 1fr 1fr;
			max-width: 1533px;
			margin: 0 auto;

			article {
				border: none;
				box-shadow: none;
				max-width: none;

				p {
					margin-left: 105px;
					max-width: 465px;
				}

				div {
					display: flex;
					align-items: center;

					img {
						margin-right: 25px;
						width: 80px;
					}
				}
			}
		}
	}

	#careers_listing_wrap {
		padding: 100px 10px;

		h2 {
			text-align: center;
			font-size: 30px;
			margin-bottom: 35px;
			font-weight: 600;
			color: #1a1c2b;
		}
	}

	#careers_listing {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 25px;

		.listing_item {
			border: 1px solid #ededed;
			border-radius: 8px;
			box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
			max-width: 1160px;
			width: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px 35px;
			box-sizing: border-box;

			.listing_item_content {
				display: flex;
				align-items: center;
				gap: 10px;
			}

			.listing_item_title h4 a {
				font-weight: 800;
				font-size: 18px;
				color: #1a1c2b;
				text-decoration: none;
			}
		}
	}


	@media screen and (max-width: 1430px) {

		#gallery_head {

			.img_group {
				display: none;
			}

			h2 {
				display: none;
			}
		}
	}

	@media screen and (max-width: 1080px) {

		#ads_features .ads_feat_img {
			padding: 0;
			display: none;
		}
	}


	@media screen and (max-width: 1080px) {

		#google_basics .basics_cards {
			display: flex;
			flex-wrap: wrap;
		}
	}

	@media screen and (max-width: 900px) {

		#cj_about {
			flex-direction: column;
			padding: 50px 25px;

			.cj_review {
				padding: 35px 0 0;
			}
		}

		#careers_listing {

			.listing_item {
				padding: 20px;
			}

			.listing_item_short_description {
				display: none;
			}

			.listing_item_rdets {
				margin-left: 15px;
			}
		}

		#careers_listing_wrap {
			padding: 50px 25px;
		}
	}

	@media screen and (max-width: 500px) {

		#google_basics  {
			padding: 45px 0;

			h2 {
				font-size: 28px;
			}

			.basics_cards {
				gap: 10px;

				article  {

					p {
						margin: 0;
					}

					div {
						flex-direction: column;
						justify-content: center;

						img {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

#view_career {

	#breadcrumb {
		background: #e7e9ee;
		max-width: none;
	}

	.header {
		background: #e7e9ee;
		padding: 20px 20px 60px;

		div {
			max-width: 1195px;
			margin: 0 auto;
		}

		h1 {
			font-size: 38px;
			color: #052460;
			padding-bottom: 15px;
			font-weight: 700;
		}

		h3 {
			color: #052460;
			font-size: 28px;
			padding-bottom: 25px;
		}

		p {
			line-height: 1.4;
			padding-bottom: 25px;
			max-width: 440px;
			color: #052460;
		}

		.btn {
			max-width: 169px;
			text-align: center;
		}
	}

	#inner_content {
		max-width: 1230px;
		margin: 0 auto;
		padding: 60px 0;

		.job_section {
			padding: 0 20px 25px;

			li {
				list-style: disc;
				list-style-position: outside;
				margin-left: 1.5em;
			}
		}

		.quote {
			border-left: 3px solid #bcbcbf;
			margin: 0 20px 25px;
			padding: 12px 0;

			h3 {
				font-size: 26px;
				font-weight: 600;
				padding-left: 10px;
			}
		}

		h2 {
			font-weight: 600;
			font-size: 24px;
			padding-bottom: 20px;
		}

		p {
			max-width: 890px;
			line-height: 1.4;
			padding: 8px 0;
		}
	}

	@media screen and (max-width: 900px) {

		.header {

			p {
				display: none;
			}

			h3 {
				font-size: 22px;
			}
		}

		#inner_content {
			padding: 40px 0;

			.job_section {

				li {
					margin-left: 20px;
				}
			}
		}
	}
}
