/* stylelint-disable */
#home_jumbo {
	position: relative;
	min-height: 800px;
	display: flex;
	background: radial-gradient(52.09% 59.93% at 104.82% 61.07%, rgba(13, 132, 103, 0.14) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0.00) 100%);

	.jumbo_lines {
		position: absolute;
		right: 0;
		bottom: 0;
		background: url('/dist/images/icons/SVG/home_line_top_right.svg');
		background-repeat: no-repeat;
		background-position: right top;
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	.closing_banner {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		box-sizing: border-box;
		max-width: 940px;
		margin: 0 auto;
		z-index: 2;

		h1 {
			font-size: 110px;
			color: #052460;
			font-weight: 700;
			line-height: normal;
			padding: 60px 0;
		}

		h3 {
			font-size: 45px;
			font-weight: 400;
			color: #434343;
			line-height: normal;
		}

		strong {
			color: #0182BA;
			font-size: 42px;
			font-weight: 700;
			line-height: normal;
			padding-bottom: 25px;
		}

		p {
			color: #434343;
			font-size: 30px;
			line-height: 50px;
			padding-bottom: 85px;

			a {
				color: #008766;
				font-size: 30px;
				line-height: 50px;
			}
		}

		.auto_logo {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.jumbo_title {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 2;
		padding-left: 80px;

		img {
			width: 200px;
		}
	}

	h1 {
		font-size: 75px;
		font-weight: 700;
		padding-top: 35px;
		color: #1a1c2b;
	}

	h2 {
		font-size: 38px;
		padding-bottom: 45px;
		color: #1a1c2b;
	}

	.jumbo_btns {
		display: flex;

		a {
			font-size: 18px;
			padding: 15px 30px;

			&:not(:first-of-type) {
				margin-left: 40px;
			}
		}
	}

	.jumbo_img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: -100px;
		left: -5px;

		img {
			width: 100%;
			z-index: 1;
			position: relative;
		}
	}

	@media screen and (max-width: 1070px) {

		.closing_banner {
			padding: 0 20px;

			h1 {
				font-size: 100px;
				padding-bottom: 18px;
				padding-top: 35px;
			}

			h3 {
				font-size: 32px;
			}

			strong {
				font-size: 32px;
			}

			p {
				font-size: 26px;
				line-height: 40px;

				a {
					font-size: 26px;
					line-height: 40px;
				}
			}
		}
	}

	@media screen and (max-width: 950px) {

		.jumbo_title {
			padding-left: 45px;
		}
	}

	@media screen and (max-width: 820px) {

		.jumbo_lines {
			background-position: top;
		}

		min-height: 607px;

		h1 {
			font-size: 60px;
		}

		h2 {
			font-size: 32px;
		}

		.jumbo_img {
			display: none;
		}

		.jumbo_title {
			align-items: center;
			padding: 0 10px;
		}
	}

	@media screen and (max-width: 768px) {

		.closing_banner {

			h1 {
				font-size: 59px;
			}

			h3 {
				font-size: 28px;
			}

			strong {
				font-size: 28px;
			}

			p {
				font-size: 24px;

				a {
					font-size: 24px;
				}
			}
		}

		h1 {
			font-size: 45px;
		}

		h2 {
			font-size: 26px;
		}

		.jumbo_title {

			img {
				height: 125px;
				width: 125px;
			}
		}

		.jumbo_btns {

			a {
				padding: 10px 25px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		min-height: 515px;

		.closing_banner {

			h1 {
				font-size: 45px;
			}

			h3 {
				font-size: 24px;
			}

			p {
				font-size: 20px;
				line-height: 32px;

				a {
					font-size: 20px;
					line-height: 32px;
				}
			}
		}

		h1 {
			font-size: 36px;
		}

		h2 {
			font-size: 22px;
		}

		.jumbo_btns {
			flex-direction: column;

			a {

				&:not(:first-of-type) {
					margin: 20px 0 0;
				}
			}
		}
	}
}
