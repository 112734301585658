#contact {

	.contact_wrap {
		max-width: 1206px;
		margin: 0 auto;
		padding-bottom: 125px;
	}
}

#contact_title {
	padding-bottom: 65px;

	h1 {
		font-size: 45px;
		font-weight: 700;
		padding-bottom: 15px;
		text-align: center;
	}

	h2 {
		font-size: 22px;
		text-align: center;
	}

	@media screen and (max-width: 640px) {
		padding: 10px 45px 30px;

		h1 {
			text-align: left;
			font-size: 30px;
		}

		h2 {
			text-align: left;
			font-size: 16px;
			max-width: 250px;
			line-height: 1.7;
		}
	}
}

#contact_form {
	padding: 30px 35px 80px;
	min-height: 500px;
	position: relative;
	overflow: hidden;

	.form_inputs {
		width: 100%;
		max-width: 850px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.send_arrow {
		position: absolute;
		bottom: 0;
		right: 60px;
		background: url('/dist/images/content/letter_send.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0;
		height: 133px;
		width: 247px;
	}

	label {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}

	input,
	textarea {
		box-sizing: border-box;
		width: auto;
		padding: 16px 0;
		margin: 0 0 30px;
		border-bottom: 1px solid #bcbec0;
		border-top: none;
		border-left: none;
		border-right: none;
		$color: $primary;
		font-size: 16px;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: #011c2a;
		}

		&.error {
			border-bottom: 1px solid rgb(124, 6, 6);
		}

		&.short {
			width: calc(50% - 10px);
		}
	}

	/* stylelint-disable */

	#message_input {
		width: 100%;
	}

	/* stylelint-enable */

	h3 {
		font-weight: 700;
		padding-bottom: 15px;
		padding-top: 20px;
	}

	textarea {
		font-family: $body;
	}

	fieldset {
		padding-bottom: 40px;

		div {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.contact_radios {
		display: flex;
		width: 100%;
		justify-content: space-between;

		label {
			position: relative;
			top: unset;
			left: unset;
			color: #011c2a;
		}

		input[type="radio"] {
			background-color: #d9d9d9;
			margin: 0;
		}
	}

	.contact_submit {
		float: right;
		z-index: 2;
		position: relative;
	}

	.response_message {
		margin-bottom: 40px;
		width: 100%;
		box-sizing: border-box;

		span {
			font-size: 16px;
			display: block;
			margin: 20px 0;
			text-align: center;

			&.error {
				color: rgb(124, 6, 6);
			}

			&.success {
				color: $accent;
			}
		}
	}

	@media screen and (max-width: 640px) {
		padding: 30px 30px 100px;

		input.short,
		textarea.short {
			width: 100%;
		}

		textarea {
			margin-bottom: 60px;
		}
	}

	@media screen and (max-width: 500px) {

		.contact_radios {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 20px;

			input[type="radio"] {
				width: auto;
			}
		}

		textarea {
			height: 200px;
			border: 1px solid #bcbec0;
			padding: 10px 15px;
		}
	}
}

#contact_card {
	box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
	background: #fff;

	.contact_card_wrap {
		display: flex;
	}

	article {
		width: 45%;
		background: #f2f4f8;
		padding: 40px 50px;

		h3 {
			font-weight: 700;
			font-size: 24px;
		}

		p {
			padding: 10px 0 50px;
		}
	}

	.foot_soc {
		display: flex;
		gap: 10px;
		justify-content: center;
		max-width: 260px;
	}

	.contact_connect {
		display: flex;
		flex-direction: column;
		gap: 30px;

		a {

			&:hover {
				color: $accent;
			}
		}

		a,
		span {
			display: flex;
			align-items: center;
			color: #58595b;

			span {
				color: #58595b;

				&:hover {
					color: $accent;
				}
			}

			img {
				margin-right: 10px;
			}
		}
	}

	@media screen and (max-width: 820px) {

		.contact_card_wrap {
			flex-direction: column;
		}

		article {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	@media screen and (max-width: 640px) {

		.contact_connect {
			width: 100%;
		}

		article {
			align-items: flex-start;

			h3 {
				display: none;
			}

			p {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
}
