#gmb {

	/* stylelint-disable */
	#gallery_head {
		border-bottom: 1px solid #dedede;
	}

	/* stylelint-enable */

	.gmb_image {
		width: 100%;
		max-width: 750px;
		margin: 0 auto;
		padding: 45px 15px 0;
		box-sizing: border-box;

		img {
			width: 100%;
		}
	}
}

#google_map {

	.map_wrap {
		display: flex;
		justify-content: center;
		padding: 90px 20px 65px;
		box-sizing: border-box;
		max-width: 1255px;
		margin: 0 auto 100px;

		article {
			margin-left: -10px;

			&:not(:first-of-type) {
				margin-left: -20px;
			}

			img {
				width: 100%;
			}

			h3 {
				text-align: center;
				font-weight: 700;
				font-size: 18px;
				margin-bottom: 20px;
			}

			p {
				line-height: 1.5;
			}

			div {
				max-width: 270px;
				padding: 60px 12px 0;
				box-sizing: border-box;
			}
		}
	}

	@media screen and (max-width: 1290px) {

		.map_wrap {

			article p {
				padding: 0 12px;
			}
		}
	}

	@media screen and (max-width: 768px) {

		.map_wrap {
			flex-wrap: wrap;
			gap: 20px;
			margin: 0 auto;

			article {
				display: flex;
				flex-direction: column;
				margin: 0;

				&:not(:first-of-type) {
					margin: 0;
				}

				img {
					width: 210px;
					margin: 0 auto;
				}

				p {
					padding: 0;
				}

				div {
					max-width: 375px;
					padding: 45px 12px 30px;
				}
			}
		}
	}
}

#content_marketing {

	/*stylelint-disable */
	.internal_content {
		background: radial-gradient(36.68% 44.74% at 1.25% 20.63%, rgba(48, 131, 187, 0.15) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0) 100%);
	}

	/*stylelint-enable */

	.content_wrap {
		display: flex;
		align-items: center;
		gap: 45px;
		max-width: 1250px;
		margin: 0 auto;
		padding: 45px 30px 65px;

		&:last-of-type {
			padding: 65px 30px 65px;
		}

		.wrap_img {
			width: 50%;
			max-height: 600px;
		}

		img {
			width: 100%;
			height: 100%;
		}

		hgroup {
			width: 50%;
		}

		h3 {
			font-weight: 700;
			font-size: 22px;
			padding-bottom: 15px;
			color: #1a1c2b;
		}

		p {
			line-height: 1.4;
			max-width: 450px;
			font-size: 18px;

			&:not(:first-of-type) {
				padding-top: 25px;
			}
		}
	}

	@media screen and (max-width: 768px) {

		.content_wrap {
			flex-direction: column-reverse;

			hgroup,
			.wrap_img {
				width: 100%;
			}

			&:last-of-type {
				flex-direction: column;
			}
		}
	}
}

#cta_banner.g_maps {

	div {
		margin-right: 0px;

		h2 {
			font-size: 38px;
		}

		h3 {
			font-size: 22px;
			color: #fff;
		}

		@media screen and (max-width: 1200px) {

			h2 {
				font-size: 30px;
			}

			h3 {
				font-size: 18px;
			}
		}


		@media screen and (max-width: 955px) {

			h2 {
				font-size: 26px;
			}

			h3 {
				font-size: 16px;
			}
		}

		@media screen and (max-width: 768px) {

			div {
				margin-right: 25px;
			}

			h2 {
				font-size: 34px;
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;
			}

			h3 {
				font-size: 20px;
			}
		}
	}
}

#google_basics {
	padding: 100px 10px;

	h2 {
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 45px;
	}

	.basics_cards {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 40px;

		article {
			box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
			border-radius: 8px;
			border: 1px solid #ededed;
			background: #fff;
			max-width: 330px;
			padding: 25px;


			h3 {
				padding: 15px 0;
				font-weight: 700;
				font-size: 18px;
			}

			p {
				line-height: 1.6;
			}
		}
	}

	@media screen and (max-width: 768px) {

		h2 {
			max-width: 300px;
			margin: 0 auto;
		}
	}
}
