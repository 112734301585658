#support {

	/* stylelint-disable */
	#internal_main.why_us {
		background-image: url(/dist/images/support_hero.jpg);
	}
	/* stylelint-enable */
}

#support_cta {
	width: 40%;

	&.mobile {
		display: none;
	}

	hgroup {
		margin-left: 85px;
		max-width: 425px;
		font-family: $footer_font;
	}

	h3 {
		font-size: 55px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 16px;
			left: -15px;
			width: 4px;
			height: 115px;
			background-color: $accent;
		}
	}

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		font-size: 20px;
		margin: 25px 0  0;
		white-space: nowrap;

		img {
			height: 20px;
			margin-right: 10px;
		}
	}
}

#support_form {
	margin: 0 10px 0 30px;

	.form_inputs {
		width: 100%;
		max-width: 850px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	label {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}

	input, textarea {
		box-sizing: border-box;
		width: 100%;
		padding: 16px;
		margin: 0 0 16px;
		border: 1px solid $grey;
		$color: $primary;
		font-size: 16px;

		&::placeholder {
			color: $black;
			font-weight: 700;
		}

		&.error {
			border: 1px solid rgb(124, 6, 6);
		}

		&.short {
			width: calc(50% - 10px);
		}
	}

	textarea {
		font-family: $body;
		min-height: 120px;
	}

	.response_message {

		span {
			font-size: 16px;
			display: block;
			margin: 20px 0;
			text-align: center;

			&.error {
				color: rgb(124, 6, 6);
			}

			&.success {
				color: $accent;
			}
		}
	}

	.btn {
		z-index: 0;
		margin-left: auto;
		display: block;
		max-width: 250px;
		height: 65px;
		background: linear-gradient(94deg, rgba(4, 19, 28, 1) 0%, rgba(38, 74, 93, 1) 100%);
	}
}

@media screen and (max-width: 1020px) {

	#support_cta {

		hgroup {
			margin-left: 30px;
		}

		h3 {
			font-size: 45px;
		}
	}
}

@media screen and (max-width: 750px) {

	#support {

		/* stylelint-disable */
		#contact_section {
			margin: 0;
		}

		#internal_wrap.why_us {
			align-items: baseline;
		}
		/* stylelint-enable */
	}

	#support_form {
		width: 85%;
		margin: 15px 0;
	}

	#support_cta {
		width: 90%;
		margin-bottom: 30px;

		h3 {
			font-size: 35px;

			&::before {
				height: 80px;
			}
		}

		a {
			margin-top: 10px;
		}
	}
}

@media screen and (max-width: 550px) {

	#support {

		/* stylelint-disable */
		#internal_main p {
			font-size: 14px;
		}

		#internal_wrap.why_us {
			align-items: center;

			h3 {
				white-space: nowrap;
			}
		}
		/* stylelint-enable */
	}

	#support_form {
		width: 90%;

		input.short {
			width: 100%;
		}

		textarea.short {
			width: 100%;
		}

		.btn {
			margin: 0 auto;
			max-width: 275px;
		}
	}

	#support_cta {
		width: 85%;

		h3 {
			font-size: 25px;

			&::before {
				top: 8px;
				height: 50px;
			}
		}

		a {
			font-size: 18px;
			margin-top: 5px;
		}
	}
}