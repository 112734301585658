#our_team {

	/* stylelint-disable */
	#gallery_head {

		p {
			padding: 10px;
			text-align: center;
			font-size: 22px;
			color: #1a1c2b;
		}

		.btn_group {
			margin-top: 25px;
		}
	}

	.team_title {
		font-size: 30px;
		font-weight: 700;
		text-align: center;
		padding: 65px;
	}
}

#our_team_section {
	max-width: 1560px;
	margin: 80px auto 10px;
	width: 100%;
	display: flex;
}

#our_team_leader {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	gap: 40px;
	max-width: 1200px;
	margin: 0 auto;
	padding: 50px 15px;
	position: relative;
	z-index: 1;

	.admin_card {
		width: 100%;
		max-width: 600px;

		.title {
			color: #1a1c2b;
			font-size: 22px;
			font-weight: 500;
			padding: 18px 0;

			span {
				font-weight: 700;
			}
		}

		.card_img {

			img {
				width: 100%;
				max-width: 600px;
			}
		}

		p {
			line-height: 1.4;
			padding-bottom: 10px;
		}

		a {
			color: $accent;
			display: flex;
			gap: 8px;
			align-items: center;
			text-decoration: underline;
			font-size: 18px;
			font-weight: 700;

			img {
				width: fit-content;
			}
		}
	}
}

#our_team_employees {
	display: flex;
	flex-wrap: wrap;
	max-width: 1560px;
	width: 100%;
	margin: 0 auto;

	.employee_card {
		width: calc(33% - 20px);
		height: auto;
		position: relative;
		margin: 0 10px 15px;

		img {
			width: 100%;
		}
	}

	.employee_card_content {
		color: #1a1c2b;
		display: flex;
		flex-direction: column;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 15px 0;
		box-sizing: border-box;

		.name {
			font-size: 18px;
			padding: 0 10px;
		}

		.title {
			color: #1a1c2b;
			font-size: 24px;
			font-weight: 700;
			padding: 0 10px;
		}
	}
}

@media screen and (max-width: 900px) {

	#our_team_leader {

		.admin_card {

			.title {
				display: flex;
				flex-direction: column;

				strong {
					display: none;
				}
			}
		}
	}

	#our_team_employees {

		.employee_card_content {

			padding: .75em;

			.name {
				font-size: 16px;
			}

			.title {
				font-size: 20px;
				display: flex;
				flex-direction: column;
			}
		}

		.employee_card {
			width: calc(50% - 20px);
		}
	}
}

@media screen and (max-width: 650px) {

	#our_team_leader {
		display: flex;
		flex-direction: column;

		.admin_card {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				max-width: 400px;
			}

			.card_img img {
				max-width: 375px;
				margin: 0 auto;
			}
		}
	}

	#our_team_mobile_contact {
		display: block;
		text-align: center;
		margin: 30px 0 0;

		p {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.btn {
			margin: 0 auto;
			max-width: 300px;
			width: 100%;
		}
	}

	#our_team_employees {

		.employee_card_content {
			padding: .25em .5em;
		}
	}
}

@media screen and (max-width: 650px) {

	#our_team_employees {

		.employee_card {
			width: 100%;
		}
	}
}
