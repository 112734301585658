#approach_img_1 {
	opacity: .75;
	display: flex;
	justify-content: center;
	margin-top: 30px;

	img {
		width: 100%;
		height: auto;
		max-width: 1050px;
	}
}

#approach {

	/* stylelint-disable */
	#google_basics {

		.basics_cards {
			max-width: 1200px;
			margin: 0 auto;

			article {
				max-width: 500px;
				min-height: 215px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px;

				img {
					height: 50px;
					width: 50px;
				}
			}
		}
	}
}

#search_support {
	background: #e4e9ef;
	padding: 85px 15px;
	box-sizing: border-box;

	.sup_wrap {
		display: flex;
		justify-content: space-between;
		max-width: 1200px;
		margin: 0 auto;
	}

	hgroup {
		color: #052460;
		max-width: 620px;

		h2 {
			font-size: 28px;
			padding-bottom: 45px;

			span {
				font-weight: 700;
			}
		}

		p {
			font-size: 18px;
			line-height: 1.5;
			padding-right: 15px;
		}
	}

	.seo_services {
		background: #fff;
		border-radius: 15px;
		box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
		padding: 30px;
		min-width: 300px;

		h3 {
			text-align: center;
			font-weight: 700;
		}

		p {
			text-align: center;
			padding: 10px 0 20px;
		}

		div {
			display: flex;
			flex-direction: column;
			gap: 20px;

			span {
				display: flex;
				align-items: center;
				gap: 10px;

				img {
					height: 25px;
				}

				p {
					padding: 0;
				}
			}
		}
	}


	@media screen and (max-width: 950px) {

		&.rvw_ctn {
			padding: 55px 30px;

			.sup_wrap {
				flex-direction: column;
				gap: 30px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		padding: 55px 20px;

		.seo_services {
			display: none;
		}

		hgroup {
			max-width: none;

			h2 {
				font-size: 25px;
				padding-bottom: 35px;
			}
		}
	}
}

#review_services {

	>div {
		display: flex;
		flex-direction: column;
		gap: 30px;

		span {
			display: flex;
			align-items: center;

			h3 {
				font-size: 28px;
				font-weight: 700;
				color: #1a1c2b;
				white-space: nowrap;
			}

			p {
				color: #004c83;
				font-weight: 700;
				white-space: nowrap;
			}

			img {
				height: 90px;
				margin-right: 20px;
			}
		}
	}


	@media screen and (max-width: 768px) {

		>div {

			span {

				img {
					height: 60px;
				}

				h3 {
					font-size: 26px;
				}

			}
		}
	}

	@media screen and (max-width: 500px) {

		>div {

			span {

				img {
					height: 60px;
					margin-right: 15px;
				}

				h3 {
					font-size: 24px;
				}

				p {
					font-size: 15px;
				}
			}
		}
	}
}

#local_search {

	.content_wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 45px;
		max-width: 1250px;
		margin: 0 auto;
		padding: 45px 30px 65px;

		&:last-of-type {
			padding: 65px 30px 90px;
		}

		.wrap_img {
			width: 50%;
			max-height: 600px;
		}

		img {
			width: 100%;
			height: 100%;
		}


		h3 {
			font-weight: 700;
			font-size: 22px;
			padding-bottom: 25px;
		}

		p {
			line-height: 1.6;
			max-width: 489px;
			font-size: 18px;

			&:not(:first-of-type) {
				padding-top: 25px;
			}
		}
	}

	@media screen and (max-width: 768px) {

		.content_wrap {
			flex-direction: column;

			hgroup,
			.wrap_img {
				width: 100%;
				max-height: none;
			}

			&:last-of-type {
				flex-direction: column-reverse;
			}
		}
	}
}

#search_wrapper {
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 15px;

	&.rv_mgt {

		img {
			max-height: 480px;
		}

		.search_cta {
			padding: 0 10px 0 40px;
		}
	}

	img {
		max-height: 638px;
	}

	.phone_img_wrap {
		position: relative;
	}

	.bubble_wrap {
		position: absolute;
		right: -45px;
		top: 30%;
	}

	.bubble {
		position: relative;
		width: 190px;
		height: 62px;
		padding: 0px;
		background: #dde1e7;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 26px;
		font-weight: 600;
		color: #052460;

		&::after {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 0 20px 20px;
			border-color: #dde1e7 transparent;
			display: block;
			width: 0;
			z-index: 1;
			top: 40%;
			left: -20px;
			transform: rotate(-90deg);
		}
	}

	h2,
	h1 {
		font-size: 38px;

		span {
			font-weight: 700;
		}
	}

	h3 {
		font-size: 24px;
		padding: 15px 0 20px;
	}

	p {
		line-height: 1.5;
		font-size: 18px;
		padding-bottom: 55px;
	}

	.btn_group {
		display: flex;
		gap: 20px;
	}

	.search_cta {
		max-width: 775px;
		padding: 0 10px 0 58px;
	}


	@media screen and (max-width: 1220px) {

		&.rv_mgt {

			img {
				max-height: 380px;
			}
		}
	}

	@media screen and (max-width: 1220px) {

		&.rv_mgt {

			img {
				display: none;
			}
		}
	}

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;

		img {
			max-height: 525px;
		}

		h2,
		h1 {
			font-size: 28px;
		}

		h3 {
			font-size: 18px;
		}

		p {
			font-size: 16px;
		}

		&.rv_mgt {

			.search_cta {
				padding: 0 20px 70px;
			}
		}

		.search_cta {
			padding: 0 20px 70px;
		}

		.btn_group {
			flex-wrap: wrap;
			justify-content: center;

			a {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 600px) {

		.bubble_wrap {
			right: -30px;
		}

		.bubble {
			width: 160px;
			height: 53px;
			font-size: 21px;
		}
	}
}

/***>>>Media Queries***/
