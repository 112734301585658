#home_services {

	.services_wrap {
		padding: 55px 10px 80px;
	}

	h2 {
		text-align: center;
		color: #1a1c2b;
		font-size: 45px;
		font-weight: 700;
		padding-bottom: 85px;
	}

	.cards_wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		max-width: 1555px;
		margin: 0 auto;
		gap: 45px;
	}

	.card {
		border-radius: 12px;
		background-color: #fff;
		border: 1px solid #ededed;
		box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
		max-width: 383px;
		display: flex;
		flex-direction: column;
	}

	.card_img_wrap {
		background: url('/dist/images/content/home_wave_card.svg');
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		height: 100%;
		min-height: 620px;

		&.ad {
			justify-content: flex-start;
		}

		&.last {
			justify-content: left;
		}

		img {
			margin: 0 auto;
			max-width: 350px;
			height: fit-content;

			&.ad {
				max-width: 375px;
				margin: auto 0 20px 0;
			}
		}
	}

	.card_ctn {
		margin-top: -183px;
		z-index: 1;
		background: #fff;
		position: relative;
		padding: 10px 25px;
		box-sizing: border-box;
		border-radius: 0 0 12px 12px;
		display: flex;
		flex-direction: column;
		height: 44%;

		h3 {
			color: #052460;
			font-weight: 700;
			font-size: 18px;
			padding-bottom: 15px;
		}

		p {
			padding-bottom: 20px;
			color: #052460;
		}
	}

	.card_lower {
		border-top: 1px solid #bcbec0;
		margin-top: auto;

		a {
			display: flex;
			align-items: center;
			color: $accent;
			text-decoration: underline;
			font-weight: 700;
			padding-top: 11px;
		}

		img {
			margin-left: 8px;
		}
	}


	@media screen and (max-width: 1265px) {

		.card {
			height: 100%;
		}
	}

	@media screen and (max-width: 950px) {

		h2 {
			font-size: 40px;
			padding-bottom: 50px;
		}

		.card_img_wrap {

			&.last {
				min-height: 500px;
			}
		}
	}

	@media screen and (max-width: 768px) {

		h2 {
			font-size: 25px;
		}

		.services_wrap {
			padding: 40px 15px;
		}

		.card_img_wrap {

			img {
				max-width: 300px;

				&.ad {
					max-width: 345px;
				}
			}
		}
	}
}
