#legality_content {
	max-width: 1520px;
	width: 100%;
	margin: 0 auto 60px;
	padding: 0 35px;
	box-sizing: border-box;

	h2,
	h3 {

		font-size: 28px;
		font-weight: 700;
		margin-bottom: 20px;
		margin-top: 40px;
	}

	h4 {

		font-size: 20px;
		font-weight: 700;
		margin-bottom: 20px;
		margin-top: 40px;
	}

	p {

		font-size: 18px;
		font-weight: 400;
		line-height: 1.8;
		margin-bottom: 20px;

		&.italics {
			font-style: italic;
		}

		&.strong {
			font-weight: 700;
		}

		span {

			&.italics {
				font-style: italic;
			}

			&.strong {
				font-weight: 700;
			}
		}
	}

	a {
		text-decoration: underline;
		color: $primary;
	}

	ol {
		list-style: decimal;

		li {
			margin-top: 10px;
			margin-left: 20px;

			span {

				&.strong {
					font-weight: 700;
				}
			}
		}
	}

	ul {
		list-style-type: disc;
		margin-bottom: 25px;

		li {
			margin-left: 40px;

			span {

				&.strong {
					font-weight: 700;
				}
			}
		}
	}

	table {

		margin: 30px 0;

		tr,
		td {
			border: 1px solid $primary;
			padding: 10px;
			box-sizing: border-box;
		}
	}
}

.access_link {
	font-family: $body;
	font-size: 18px;
	margin: 5px 0;
}

@media screen and (max-width: 750px) {

	#legality_content {

		padding: 0 15px;

		h2,
		h3 {
			font-size: 20px;
			margin-bottom: 5px;
		}

		h4 {
			font-size: 18px;
		}

		p {
			font-size: 15px;
			line-height: 1.4;
			margin-bottom: 10px;
		}

		ul {

			li {
				margin-left: 20px;
			}
		}
	}

	.access_link {

		font-size: 15px;
	}
}

@media screen and (max-width: 500px) {

	#legality_content {

		h2,
		h3 {
			font-size: 16px;
		}

		h4 {
			font-size: 15px;
		}

		p {
			font-size: 14px;
		}

		a {
			word-break: break-all;
		}
	}
}
