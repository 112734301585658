/* stylelint-disable */
#footer {
	display: flex;
	flex-direction: column;
	max-width: 1920px;
	margin: 0 auto;
	padding: 30px 80px;
	box-sizing: border-box;
	width: 100%;
	background: #f5f5f5;
	position: relative;

	.top_logo {
		display: flex;
		justify-content: center;
		padding: 0 0 25px;
		border-bottom: 2px solid #dedede;
	}

	.foot_logo {
		height: 69px;
	}
}

#has_drop {
	text-decoration: underline;
	font-weight: 600;
	padding: 5px 0;

	&:first-of-type {
		padding: 0 0 5px 0;
	}

	&.inner_drop {
		color: $accent;
	}
}

#footer_items {
	display: flex;
	justify-content: space-between;
	padding: 45px 0 0;

	.foot_soc {
		display: flex;
		padding-top: 10px;

		img {
			padding: 0 5px;
		}
	}

	article {
		display: flex;
		flex-direction: column;

		&:not(:first-of-type):not(:last-of-type) {
			padding: 0 10px;
		}

		&:first-of-type {

			h3 {
				padding-left: 58px;
			}
		}

		&:first-of-type {

			a,
			span {
				padding: 5px 0;
				line-height: 1.4;
			}
		}

		h3 {
			font-size: 18px;
			font-weight: 700;
			color: $accent;
			padding-bottom: 15px;
		}

		a,
		span {
			display: flex;
			align-items: center;
			line-height: 1.8;
			color: #58595b;
			cursor: pointer;

			&:hover {
				color: $accent;
			}

			img {
				padding-right: 10px;
			}
		}
	}

	.dropdown {
		display: none;

		ul {
			display: none;
		}
	}

	#to_top {
		border: none;
		background: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: fit-content;
		cursor: pointer;

		img {
			height: 62px;
		}

		span {
			color: $accent;
			font-weight: 700;
			font-size: 20px;
			padding-top: 12px;
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width: 1366px) {

	#footer {
		padding: 30px 20px;
	}

	#footer_items {

		#to_top {
			position: absolute;
			right: 42px;
			top: 12px;
		}
	}
}

@media screen and (max-width: 1290px) {

	#footer_items {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		justify-items: center;
		gap: 30px;

		article {

			&:not(:first-of-type):not(:last-of-type) {
				padding: 0;
			}
		}
	}

	#footer {
		margin: 0 auto;
	}
}

@media screen and (max-width: 768px) {

	#footer_items {
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 25px;

		article {
			padding-left: 0;
			min-width: 200px;

			&:not(:first-of-type):not(:last-of-type) {
				padding: 0;
			}

			&:first-of-type {
				padding-left: 0;

				h3 {
					text-align: left;
					padding-left: 45px;
				}

				a {
					padding: 10px 0;
				}
			}
		}

		#to_top {
			top: 30px;

			img {
				height: 52px;
			}
		}

		.foot_soc {
			padding-left: 35px;
			justify-content: left;

			li {

				&:not(:first-of-type) {
					padding-left: 5px;
				}
			}

			img {
				padding: 0;
			}
		}
	}

	#footer {
		margin: 0 auto;

		.top_logo {
			border: none;
			padding: 0;
		}

		.foot_logo {
			height: 169px;
		}
	}
}
