#internal_wrap {

	&.contact {
		flex-direction: column;
		justify-content: center;

		div {
			display: flex;
			justify-content: space-between;
		}
	}
}

#articles_content, #article_content {
	width: 100%;
	margin: 45px auto;
	max-width: 1710px;

	.article_items, .article_content_inner {
		width: 100%;
		max-width: 1560px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	.article_items {
		padding-left: 200px;

		.article_item {
			display: flex;
			align-items: center;
			margin-bottom: 50px;
			font-size: 20px;

			img {
				width: 125px;
				margin-right: 25px;
			}
		}
	}

	.article_content_inner {

		h2 {
			margin: 1em 0;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
		}

		h3 {
			font-weight: 500;
		}

		p {
			max-width: 50%;
			margin: 0 auto 1em;
		}
	}

	.article_bg_section {
		width: 50%;
		margin: 0 auto 1em;
		padding: 2em 3em 1em;
		background: #f2f2f2;
		box-sizing: border-box;

		p {
			position: relative;
			max-width: none;

			&::before {
				position: absolute;
				content: "";
				top: 50%;
				right: calc(100% + 15px);
				width: 15px;
				height: 15px;
				background: $primary;
				border-radius: 3px;
				transform: translateY(-50%);
			}
		}
	}

	.article_page_tip {
		width: 50%;
		display: flex;
		align-items: center;
		margin: 0 auto;

		p {
			max-width: none;
			margin: 0 0 0 25px;
		}

		span {
			font-weight: 700;
		}
	}
}

@media screen and (max-width: 950px) {

	#articles_content {
		width: 100%;
		padding: 0 1em;
		box-sizing: border-box;

		.article_items {
			padding-left: 0;
		}
	}

	#article_content {
		padding: 0 1em;
		box-sizing: border-box;

		.article_content_inner {

			p {
				max-width: none;
			}
		}

		.article_page_tip {
			width: 100%;
		}

		.article_bg_section {
			width: 100%;
		}
	}
}

@media screen and (max-width: 750px) {

	#article_content {
		margin: 0;
		padding: 1em;

		.article_page_tip {

			img {
				display: none;
			}

			p {
				margin: 0;
			}
		}
	}
}