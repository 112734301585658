#campaign_ctn {
	max-width: 820px;
	margin: 0 auto;
	padding: 80px 10px 110px;

	h1 {
		font-size: 45px;
		text-align: center;
		color: #1a1c2b;

		span {
			font-weight: 700;
		}
	}

	@media screen and (max-width: 950px) {

		h1 {
			font-size: 38px;
		}
	}

	@media screen and (max-width: 768px) {
		padding: 45px 30px 60px;

		h1 {
			font-size: 33px;
			text-align: left;
		}
	}
}

/*stylelint-disable */
#campaign {

	.internal_content {
		background: radial-gradient(36.68% 44.74% at 1.25% 20.63%, rgba(48, 131, 187, 0.15) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0) 100%);
	}
}

/*stylelint-enable*/

#ads_features {
	display: flex;

	article {
		width: 50%;
		background: #e2e7ec;
		padding: 75px 80px;
		display: flex;
		flex-direction: column;
		color: #052460;
	}

	h2 {
		font-size: 32px;
		max-width: 645px;
		width: 100%;
		margin: 0 auto 45px;

		span {
			font-weight: 700;
		}
	}

	.feat_items {
		width: 100%;
		max-width: 645px;
		margin: 0 auto;
	}

	h3 {
		font-weight: 700;
		font-size: 26px;
	}

	p {
		padding-bottom: 40px;
		font-size: 18px;
	}

	hgroup {
		display: flex;
		flex-direction: column;
		max-width: 650px;
	}

	.ads_feat_img {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		img {
			width: 90%;
		}
	}

	@media screen and (max-width: 1180px) {
		flex-direction: column;

		article {
			width: 100%;
			box-sizing: border-box;
		}

		h2,
		.feat_items {
			max-width: 900px;
		}

		.ads_feat_img {
			width: 100%;
			padding: 45px 0;
			display: none;

			img {
				min-height: 285px;
			}
		}
	}

	@media screen and (max-width: 768px) {

		article {
			padding: 45px 30px;
		}

		h2 {
			font-size: 25px;
			line-height: normal;
			margin-bottom: 25px;
		}

		h3 {
			font-size: 18px;
		}

		p {
			font-size: 16px;
		}
	}
}

#card_caro {
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;

	/* stylelint-disable */
	#slide_left,
	#slide_right {
		cursor: pointer;

		img {
			height: 40px;
		}
	}

	#carousel_container {
		display: flex;
		transition: transform .5s ease;
	}

	/* stylelint-enable*/

	.carousel_slide {
		display: none;

		&.active {
			display: flex;
		}
	}

	div {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	img {
		max-height: 670px;
	}

	button {
		border: none;
		background: transparent;
	}

	@media screen and (max-width: 1080px) {
		display: none;
	}
}

/* stylelint-disable */

#google_ads {

	#home_services {

		.card_lower {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			button {
				text-decoration: underline;
				background: transparent;
				border: none;
				color: $accent;
				font-weight: 700;
				font-size: 18px;
				cursor: pointer;
			}
		}
	}
}

#double_card {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1310px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 10px 100px 10px;

	div {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	article {
		border-radius: 8px;
		border: 1px solid #ededed;
		box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, .10);
		background: #fff;
		padding: 20px 25px;
		max-width: 480px;
		width: 100%;
		box-sizing: border-box;

		&.left {
			display: flex;
			justify-content: space-between;
			width: 100%;

			h2 {
				margin-bottom: 0;
				width: 100%;
				justify-content: flex-end;
				text-align: right;

				img {
					margin-right: 0;
					margin-left: 20px;
				}
			}
		}

		&.right {
			display: flex;
			justify-content: space-between;
			width: 100%;

			h2 {
				margin-bottom: 0;
				width: 100%;
			}
		}

		h2 {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 18px;
			margin-bottom: 10px;
			color: #1a1c2b;

			img {
				margin-right: 10px;
				height: 35px;
			}
		}

		p {
			padding: 0 20px 0 30px;
			font-size: 16px;
			margin-bottom: 15px;
			color: #1a1c2b;
		}
	}

	.main_logo {
		height: 345px;
		width: 345px;
		padding: 0 30px;
	}


	@media screen and (max-width: 1180px) {

		article {

			h2 {
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 1080px) {

		.main_logo {
			height: 260px;
			width: 280px;
		}
	}

	@media screen and (max-width: 950px) {
		flex-direction: column;
		align-items: center;
		padding: 0 10px 65px 10px;

		article {

			&.left {

				h2 {
					justify-content: left;
					flex-direction: row-reverse;
					text-align: left;

					img {
						margin-right: 10px;
						margin-left: 0;
					}
				}
			}
		}

		div {

			&:last-of-type {
				margin-top: 20px;
			}
		}

		.main_logo {
			display: block;
			order: -1;
			margin-bottom: 50px;
		}
	}
}
