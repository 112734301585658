/* stylelint-disable */
.internal_content {
	overflow: hidden;
	background: radial-gradient(52.09% 59.93% at -4.82% 61.07%, rgba(13, 132, 103, 0.14) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0.00) 100%);
	position: relative;

	.jumbo_lines_left {
		position: absolute;
		left: -440px;
		bottom: 805px;
		background: url(/dist/images/icons/SVG/lines_top_left.svg);
		background-repeat: no-repeat;
		background-position: left top;
		width: 100%;
		height: 100%;
		z-index: 0;
		opacity: .5;
		pointer-events: none;
	}

	.jumbo_lines_right {
		position: absolute;
		left: 330px;
		bottom: 180px;
		background: url(/dist/images/icons/SVG/lines_top_right.svg);
		background-repeat: no-repeat;
		background-position: right top;
		width: 100%;
		height: 100%;
		z-index: 0;
		opacity: .5;
		pointer-events: none;
	}
}

#local_search,
#approach

/*,
#our_team*/
	{

	.internal_content {
		background: radial-gradient(36.68% 44.74% at 98.75% 20.63%, rgba(48, 131, 187, 0.15) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0) 100%);
	}
}

#coming_soon {
	min-height: 650px;

	.center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {
			max-height: 352px;
		}

		h1 {
			font-size: 47px;
			font-weight: 700;
			line-height: normal;
			text-align: center;
		}
	}
}

#business_intelligence,
#conversion_optimization,
#google_map,
#gmb,
#google_ads,
#blog,
#blog_post_container {

	.internal_content {
		background: radial-gradient(36.68% 44.74% at 1.25% 20.63%, rgba(48, 131, 187, 0.15) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0) 100%);
	}
}

/* stylelint-enable*/

#hp_wrap {
	position: fixed;
	top: -9999px;
	left: -9999px;
}

#breadcrumb {
	max-width: 1753px;
	margin: 0 auto;
	padding: 25px 30px;
	display: flex;

	li {
		list-style-type: none;
		position: relative;
		color: #052460;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		a {
			color: #052460;
			text-transform: uppercase;
			white-space: nowrap;
		}

		&:not(:first-of-type) {
			margin-left: 15px;

			&:before {
				content: '';
				position: absolute;
				border-right: 1px solid black;
				border-bottom: 1px solid black;
				width: 5px;
				height: 5px;
				top: calc(50% - 0px);
				left: -12px;
				color: #052460;
				transform: translateY(-50%) rotate(-45deg);
			}
		}
	}
}

#internal_caro {
	width: 100%;
	margin: 45px auto;
	max-width: 1710px;
}

#eco_sec {

	>h2 {
		font-weight: 600;
		font-size: 28px;
		padding-bottom: 85px;
		text-align: center;
	}
}

#internal_info {
	max-width: 1560px;
	margin: 0 auto;
	width: 100%;
	font-family: $footer_font;
	position: relative;
	z-index: 1;
}

#poke {
	position: absolute;
	width: 280px;
	height: 280px;
	background-image: url(/dist/images/icons/SVG/green_dot_pattern.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	z-index: -1;

	&.left {
		top: -35px;
		left: -35px;
	}

	&.right {
		top: none;
		bottom: -35px;
		right: -35px;
	}
}


#internal_main {

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 460px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;

	.title {
		color: $grey;
		font-size: 60px;
		font-weight: 700;
	}

	hgroup {
		margin-top: 20px;
	}

	h2 {
		font-size: 25px;
		margin-top: 15px;
		position: relative;
		color: $white;
		font-weight: 700;

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			left: -15px;
			width: 4px;
			height: 70px;
			background-color: $accent;
		}

		&.tagline,
		&.tagline_bullets {
			width: max-content;
			font-size: 18px;
			color: $white;
			font-weight: 700;
			padding: 20px 0;
			margin: 20px 0;
			letter-spacing: 2px;
			border-style: solid;
			border-width: 2px;
			border-image: linear-gradient(to left, rgba(137, 194, 66, 0) 1%, rgba(51, 173, 101, 1) 50%, rgba(137, 194, 66, 0) 100%) 100% 0 100% 0/2px 0 2px 0 stretch;

			&.marketing {
				width: 453px;
				font-size: 16px;
				line-height: 1.7;
			}

			&::before {
				content: none;
			}

			&.our_team,
			&.careers {
				width: auto;
			}
		}

		&.tagline_phone {
			font-size: 25px;
			color: $white;
			font-weight: 700;
			margin: 0;

			&::before {
				content: none;
			}
		}
	}

	h3 {
		width: max-content;
		font-size: 18px;
		color: $white;
		font-weight: 700;
		padding: 20px 0;
		margin: 20px 0;
		letter-spacing: 2px;
		border-style: solid;
		border-width: 2px;
		border-image: linear-gradient(to left, rgba(137, 194, 66, 0) 1%, rgba(51, 173, 101, 1) 50%, rgba(137, 194, 66, 0) 100%) 100% 0 100% 0/2px 0 2px 0 stretch;

		&.marketing {
			width: 453px;
			font-size: 16px;
		}
	}

	h4 {
		font-size: 25px;
		color: $white;
		font-weight: 700;
	}

	p {
		color: $white;
		font-size: 16px;
		max-width: 740px;
		width: 100%;
		line-height: 1.4;
	}

	a {
		text-decoration: none;
		cursor: pointer;
		color: $white;
	}

	span {
		font-size: 60px;
		font-weight: 700;
		color: $white;
	}

	&.contact {
		background-image: url(/dist/images/contact_us_hero.jpg);
	}

	&.why_us {

		background-image: url(/dist/images/why_us_hero.jpg);
		background-position: center center;
		height: auto;
		padding: 60px 0;
		margin-bottom: 30px;

		hgroup {

			margin-left: 112px;
		}

		h3,
		h2.tagline,
		h2.tagline_bullets {
			width: max-content;
			font-size: 18px;
			color: $white;
			font-weight: 700;
			padding: 20px 0;
			margin: 20px 0;
			letter-spacing: 2px;
			border-style: solid;
			border-width: 2px;
			border-image: linear-gradient(to left, rgba(137, 194, 66, 0) 1%, rgba(51, 173, 101, 1) 50%, rgba(137, 194, 66, 0) 100%) 100% 0 100% 0/2px 0 2px 0 stretch;

			&.secondary_tagline {
				font-size: 25px;
				color: $white;
				font-weight: 700;
				padding: 0;
				margin: 0;
				letter-spacing: normal;
				border: 0;
				line-height: 32.5px;
			}
		}
	}

	&.legality {

		height: 270px;
	}

	&.legality.accessibility,
	&.error {
		background-image: url(/dist/images/accessibility_header.jpg);
	}

	&.legality.privacy_policy {
		background-image: url(/dist/images/privacy_policy_header.jpg);
	}

	&.our_team,
	&.careers {

		background-image: url(/dist/images/our_team_hero.jpg);
		height: auto;
		padding: 60px 0;

		.title {
			white-space: nowrap;
		}

		h3 {
			width: auto;
		}
	}

	&.articles {
		background-image: url(/dist/images/articles_hero.jpg);
		height: 400px;

		hgroup {
			margin-top: 0;
		}

		h2 {
			margin-top: 0;

			&.tagline {
				margin-top: 20px;
			}
		}
	}

	&.article_page {
		background-image: url(/dist/images/articles_hero.jpg);
		height: 400px;

		.title {
			text-align: center;
			font-size: 48px;
		}
	}

	&.marketing {
		height: 520px;
		padding: 80px 0 0;
	}

	&.marketing.overview {
		background-image: url(/dist/images/marketing_hero.jpg);
	}

	&.marketing.seo {
		background-image: url(/dist/images/seo_hero.jpg);
	}

	&.marketing.paid {
		background-image: url(/dist/images/paid_search_hero.jpg);
	}

	&.marketing.call {
		background-image: url(/dist/images/call_tracking_hero.jpg);
	}

	&.marketing.local_search {
		background-image: url(/dist/images/local_search.jpg);
	}

	&.marketing.rep {
		background-image: url(/dist/images/reputation_hero.jpg);
	}

	&.error {
		height: 300px;
		padding: 80px 0 0;
	}
}

#internal_wrap {
	max-width: 1300px;
	margin: 0 auto;
	padding: 0 35px;
	height: 100%;
	width: 100%;
	display: flex;

	&.contact {
		flex-direction: column;
		justify-content: center;

		div {
			display: flex;
			justify-content: space-between;
		}
	}

	&.why_us {

		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 20px;

		h2.tagline,
		h2.tagline_bullets {
			max-width: 690px;
			width: 100%;
			line-height: 1.8;
		}

		h3 {
			max-width: 690px;
			width: 100%;
			line-height: 1.8;
		}
	}

	&.legality {

		.title {
			margin-top: 70px;
		}
	}

	&.our_team,
	&.careers {

		justify-content: space-between;
		box-sizing: border-box;

		.right {

			margin-left: 35px;
		}
	}

	&.articles {

		div {
			display: flex;
			justify-content: space-between;

			&.articles_section {
				flex: 1;
				flex-direction: column;
				justify-content: center;

				&.mobile {
					display: none;
				}
			}
		}
	}

	&.article {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&.marketing {

		.right {

			margin-left: 190px;
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width: 1250px) {

	#internal_main {

		&.article_page {

			.title {
				font-size: 36px;
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	#internal_main {

		&.why_us {

			padding: 30px 0;

			hgroup {
				margin-left: 0;
			}

			h2 {
				font-size: 20px;

				&:before {
					display: none;
				}
			}

			p {
				max-width: 100%;
			}
		}
	}

	#internal_wrap {

		&.why_us {

			flex-direction: column;
			box-sizing: border-box;
			padding: 0 20px;

			h3 {
				margin: 0;
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: 1020px) {

	#internal_main {

		.title {
			font-size: 52px;
		}

		h3 {
			font-size: 16px;
		}

		span {
			font-size: 52px;
		}
	}
}

@media screen and (max-width: 950px) {

	#internal_wrap {
		padding: 0 10px;
	}

	#internal_main {

		.title {
			font-size: 45px;
		}

		h2 {
			font-size: 22px;
		}

		h3 {
			font-size: 16px;
		}

		span {
			font-size: 45px;
		}

		hgroup {
			margin-top: 15px;
		}

		&.article_page {

			.title {
				font-size: 30px;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#internal_main {
		height: auto;
		padding: 25px 10px;

		h2.tagline {
			font-size: 13px;
			border-width: 1px;

			&.our_team {
				width: 200px;
				text-align: center;
			}

			&.careers {
				text-align: center;
			}
		}

		h3 {
			font-size: 12px;
			border-width: 1px;
		}

		hgroup:not(:first-child) {
			margin-left: 20px;
		}

		&.why_us {

			background-position: 75% 100%;
		}

		&.legality,
		&.error {

			height: 150px;
		}

		&.error {
			padding: 0;
		}

		&.articles {
			height: auto;
		}

		&.article_page {
			height: 180px;

			.title {
				font-size: 18px;
			}
		}
	}

	#internal_wrap {
		align-items: center;

		div {
			flex-direction: column;
		}

		&.legality {

			.title {
				margin-top: 0;
			}
		}

		&.articles {
			flex-direction: column;

			div {

				&.articles_section {
					margin-top: 20px;

					&.mobile {
						display: block;

						.mobile_heading {
							color: #fff;
							text-align: center;
						}

						a {
							display: block;
							margin-top: 10px;
							padding: 10px 50px;
							text-align: center;
							font-size: 14px;
							font-weight: 700;
							border: 2px solid #fff;
							border-radius: 20px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {

	#internal_caro {
		margin: 0 auto;

		&.legality,
		&.error {

			margin: 0 auto 45px;
		}
	}

	#internal_main {
		padding: 20px 0;

		h3 {
			margin: 10px 0;
		}

		span {
			font-size: 35px;
		}

		&.why_us {

			.title {
				text-align: center;
			}

			hgroup {
				padding: 0 20px;
				margin-bottom: 30px;
			}
		}
	}

	#internal_wrap {

		&.why_us {
			padding: 0 15px;

			h3 {
				font-size: 12px;
			}
		}
	}

	#poke {
		display: none;
	}
}

@media screen and (max-width: 1100px) {

	#internal_main {

		&.why_us {

			padding: 30px 0;

			hgroup {
				margin-left: 0;
			}

			h2 {
				font-size: 20px;

				&:before {
					display: none;
				}
			}
		}
	}

	#internal_wrap {

		&.why_us {

			flex-direction: column;
			box-sizing: border-box;
			padding: 0 20px;

			.right {

				margin-left: 0;
			}

			h3 {
				margin: 0;
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: 900px) {

	#internal_main {

		&.our_team,
		&.careers {

			padding: 30px 0;
		}
	}

	#internal_wrap {

		&.our_team,
		&.careers {

			flex-direction: column;

			.right {

				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#internal_main {

		h3 {

			&.our_team,
			&.careers {

				width: 200px;
				text-align: center;
			}
		}
	}

	#internal_info {

		&.our_team,
		&.careers {

			.title {
				font-size: 36px;
			}

			h2 {
				margin-top: 0;
				font-size: 20px;
			}

			h3 {
				letter-spacing: 1px;
				margin: 10px 0;
			}

			hgroup {
				margin-top: 0;
			}
		}
	}
}
