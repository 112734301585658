#conversion_optimization {

	/*stylelint-disable */
	#gallery_head {

		p {
			padding: 45px 10px 0px;

			&:last-of-type {
				padding: 20px 10px 45px;
			}
		}

		@media screen and (max-width: 768px) {

			&.dubp {

				p {
					padding: 25px 0;

					&:last-of-type {
						padding: 0 0 45px;
					}
				}
			}

			p {
				padding: 25px 0;

				&:last-of-type {
					padding: 20px 0 45px;
				}
			}

			padding: 30px 20px 30px;
		}
	}

	#google_basics {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1260px;
		margin: 0 auto;

		@media screen and (max-width: 768px) {
			padding: 45px 25px;
		}
	}
}

#business_intelligence {

	#gallery_head {

		p {
			padding: 45px 10px 0px;

			&:last-of-type {
				padding: 20px 10px 45px;
			}
		}
	}

	#google_basics .basics_cards article {
		padding: 45px 30px;
	}
}

#lead_graph,
#metrics_line {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 35px;
	padding-bottom: 90px;
}

#bi_img {
	display: flex;
	justify-content: center;
	padding: 0 15px 100px;

	&.rm_pg {
		padding: 100px 15px;
	}

	img {
		width: 100%;
		height: 100%;
		max-width: 768px;
	}
}

#lead_graph {
	justify-content: space-evenly;
	padding: 0 10px 100px;

	&.mobile {
		display: none;
	}

	img {
		height: 142px;
	}

	@media screen and (max-width: 768px) {
		display: none;

		&.mobile {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				height: 350px;
			}
		}
	}
}

#metrics_line {

	img {
		width: 300px;
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
}

#converstion_accords {
	max-width: 1166px;
	margin: 0 auto;
	padding: 0 25px 150px;
	box-sizing: border-box;

	h1 {
		font-size: 36px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	#acco_context {
		display: none;
		flex-direction: column;
		max-width: 965px;
	}

	#accordion {
		border-bottom: 4px solid rgba(112, 112, 112, .5);
		position: relative;
	}

	#acco_button {
		position: absolute;
		right: 10px;
		top: 34%;
		transform: translate(0%, 0%);
		width: 38px;
		height: 38px;
		background: url('/dist/images/icons/SVG/acco_button.svg');
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;

		&:active,
		&.clicked {
			background: url('/dist/images/icons/SVG/acco_button_close.svg');
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	/* stylelint-enable */

	h2 {
		font-weight: 700;
		font-size: 45px;
		padding-bottom: 60px;
	}

	h3 {
		font-size: 31px;
		font-weight: 600;
		padding: 38px 48px 38px 0;
		position: relative;
	}

	p {
		padding-bottom: 30px;
		font-size: 18px;
	}

	a {
		color: $accent;
		padding-bottom: 25px;
		font-size: 18px;
		display: flex;
		align-items: center;

		img {
			margin-left: 8px;
		}
	}

	@media screen and (max-width: 820px) {

		h1 {
			font-size: 25px;
		}

		.solutions_wrap {
			padding: 45px 10px 70px;
		}

		h2 {
			padding-bottom: 40px;
		}

		h3 {
			font-size: 24px;
			padding: 30px 42px 30px 0;
		}

		p,
		a {
			font-size: 16px;
		}

		/* stylelint-disable */
		#accordion {
			border-bottom: 2px solid rgba(112, 112, 112, .5);
		}

		/* stylelint-enable*/
	}

	@media screen and (max-width: 500px) {

		h3 {
			font-size: 20px;
			padding: 25px 32px 25px 0;
		}

		p,
		a {
			font-size: 16px;
			padding: 0 15px 30px;
		}

		/* stylelint-disable */
		#acco_button {
			width: 30px;
			height: 30px;
		}

		/* stylelint-enable*/
	}
}
