#reviews {
	background: linear-gradient(180deg, rgba(4, 19, 28, 1) 0%, rgba(38, 74, 93, 1) 100%);
	position: relative;

	&::after {
		position: absolute;
		content: '';
		background: $white;
		width: 50%;
		height: 65%;
		top: -1px;
		right: 0;
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%);
	}

	.top_social {
		max-width: 1270px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		z-index: 1;
		padding: 30px 0;


		h3 {
			font-size: 18px;
		}

		div {
			display: flex;
			margin-left: 50px;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				background: linear-gradient(166deg, rgba(137, 194, 66, 1) 0%, rgba(40, 98, 64, 1) 100%);
				border-radius: 500px;

				&:not(:last-child) {
					margin-right: 20px;
				}
			}
		}
	}

	.reviews_main {
		max-width: 1560px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		align-items: center;

		.reviews_info {
			width: 100%;
			max-width: 615px;
			min-width: 375px;
			display: flex;
			flex-direction: column;
			font-family: $body;
			margin: 0 10px;

			.title {
				font-size: 60px;
			}

			h3 {
				width: 100%;
				max-width: 345px;
				font-family: $footer_font;
				font-size: 32px;
				color: $white;
				margin: 30px 0;

				strong {
					font-weight: 700;
				}
			}

			/* stylelint-disable */
			#star_ratings {
				max-width: 550px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					font-size: 18px;
					color: $white;

					strong {
						font-weight: 700;
					}
				}

				div {
					margin-right: 35px;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						height: 70px;
						width: 3px;
						top: 5px;
						right: 70px;
						background-color: $grey;
					}

					img {
						padding: 30px;
						cursor: pointer;
					}
				}
			}
			/* stylelint-enable */
		}

		.reviews_data {
			display: flex;
			box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, .75);
			z-index: 1;
			position: relative;

			.inner {
				width: 100%;
				height: 100%;
				position: absolute;
				border: 1px solid $accent;
				left: -30px;
				bottom: -30px;
				z-index: -1;
			}

			.review_img {
				width: 255px;
				height: 455px;
				background-color: $accent;
				position: relative;

				img {
					position: absolute;
					height: 375px;
					width: 250px;
					bottom: 0;
					right: -25px;
				}
			}
		}
	}

	.btm_quote {
		max-width: 1370px;
		width: 100%;
		margin: 0 auto;
		padding: 50px 0;
		display: flex;
		justify-content: space-between;

		h2 {
			font-size: 55px;
			font-weight: 700;
			color: #376a7d;
			letter-spacing: 5px;
			padding: 0 10px;
		}

		.btn {
			z-index: 0;
			padding: 0;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			margin-right: 10px;

			&:hover {
				color: $accent;
			}
		}
	}
}

#reviews_caro {
	width: 640px;
	overflow: hidden;
	height: 100%;
	display: flex;
	align-items: center;

	.caro_inner {
		width: 100%;
	}

	.caro_slide {
		margin: 0 100px 45px;
		max-width: 450px;

		.reviewer_data {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 15px 0;

			h4.reviewer_name {
				font-size: 20px;
				font-weight: 700;
			}

			h6 {
				font-size: 20px;
				font-weight: 700;
			}
		}

		p {
			font-size: 18px;
			line-height: 1.5;
		}
	}
}

#caro_wrap {
	background-color: #fff;
}

@media screen and (max-width: 1300px) {

	#reviews {

		.reviews_main {

			.reviews_info {

				/* stylelint-disable */
				#star_ratings div{
					margin: 0;
				}
			}

			.reviews_data {

				.review_img {
					height: 350px;
					width: 190px;

					img {
						height: 300px;
						width: 200px;
					}
				}
			}
		}

		.btm_quote {

			h2 {
				font-size: 40px;
			}
		}
	}

	#reviews_caro {
		width: 440px;

		.caro_slide {
			margin: 0 78px 45px;
			max-width: 350px;

			p {
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: 950px) {

	#reviews {

		&::after {
			content: none;
		}

		.top_social {
			background: #fff;
			box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, .75);
			justify-content: center;
		}

		.reviews_main {
			flex-direction: column;

			.reviews_info {

				h3 {
					margin: 5px 0;
				}

				.title {
					color: #37687B;
				}

				#star_ratings {
					margin-bottom: 10px;
				}
			}
		}

		.btm_quote {
			flex-direction: column;
			text-align: center;
			align-items: center;
		}
	}
}

@media screen and (max-width: 750px) {

	#reviews {

		.reviews_main {
			flex-direction: column;

			.reviews_info {
				width: 90%;

				.title {
					font-size: 45px;
				}

				h3 {
					font-size: 25px;
				}
			}

			.reviews_data {
				width: 95%;

				.inner {
					display: none;
				}

				.review_img {
					width: 100%;
					max-width: 180px;
					height: auto;
					min-height: 315px;
				}
			}
		}

		.btm_quote {
			padding: 32px 0;
			
			h2 {
				font-size: 32px;
				max-width: 350px;
				margin-bottom: 25px;
			}
		}
	}

	#caro_wrap {
		width: 100%;
	}

	#reviews_caro {
		width: 100%;

		.caro_slide {
			max-width: 98%;
			margin: 0;

			.review_top, p {
				padding: 5px;
			}
		}
	}
}

@media screen and (max-width: 500px) {

	#reviews .reviews_main {

		.reviews_info {
			min-width: auto;

			#star_ratings div {
			
				&::before {
					right: 50px;
					height: 50px;
				}

				img {
					padding: 20px;
				}
			}
		}

		.reviews_data {
			width: 95%;

			.review_img {
				max-width: 110px;
				min-height: auto;

				img {
					height: 185px;
					width: 120px;
					right: -15px;
				}
			}
		}
	}

	#reviews_caro {

		.caro_slide {

			p {
				font-size: 14px;
			}

			.review_top {

				img {
					height: 25px;
				}
			}

			.reviewer_data {

				h6 {
					font-size: 18px;
				}
			}
		}
	}
}