#home {
	box-sizing: border-box;

	.content {
		overflow: hidden;
	}
}

#home_stats {
	background: #e6eaf0;

	.stats_wrap {
		display: flex;
		justify-content: space-evenly;
		padding: 60px 10px;
	}

	article {
		display: flex;
		align-items: center;
		font-weight: 700;
		padding: 0 5px;

		div {
			display: flex;
			flex-direction: column;
		}

		h3 {
			font-size: 32px;
		}

		p {
			font-size: 18px;
			color: #004c83;
		}

		img {
			height: 105px;
			margin-right: 25px;
		}
	}

	@media screen and (max-width: 1260px) {

		.stats_wrap {
			padding: 45px 10px;
		}

		article {

			img {
				height: 90px;
			}

			h3 {
				font-size: 28px;
			}

			p {
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 950px) {

		article {

			img {
				height: 70px;
			}

			h3 {
				font-size: 22px;
				white-space: nowrap;
			}

			p {
				font-size: 14px;
			}
		}
	}


	@media screen and (max-width: 768px) {

		.stats_wrap {
			flex-direction: column;
			align-items: center;
			padding: 25px 10px;
		}

		article {
			padding: 20px 0;
			width: 335px;
		}
	}
}

#home_solutions {
	color: #1a1c2b;

	.solutions_wrap {
		max-width: 1200px;
		margin: 0 auto;
		padding: 70px 10px 120px;
		box-sizing: border-box;
	}

	/* stylelint-disable */
	#acco_context {
		display: none;
		flex-direction: column;
		max-width: 835px;
	}

	#accordion {
		border-bottom: 4px solid rgba(112, 112, 112, .5);
		position: relative;
	}

	#acco_button {
		position: absolute;
		right: 0;
		top: 27%;
		transform: translate(0%, 0%);
		width: 52px;
		height: 52px;
		background: url('/dist/images/icons/SVG/acco_button.svg');
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;
		z-index: 2;

		&:active,
		&.clicked {
			background: url('/dist/images/icons/SVG/acco_button_close.svg');
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	/* stylelint-enable */

	h2 {
		font-weight: 700;
		font-size: 45px;
		padding-bottom: 60px;
		color: #1a1c2b;
	}

	h3 {
		font-size: 31px;
		font-weight: 600;
		padding: 38px 48px 38px 0;
		position: relative;
		color: #1a1c2b;
	}

	p {
		padding-bottom: 30px;
		font-size: 18px;
	}

	a {
		color: $accent;
		padding-bottom: 25px;
		font-size: 18px;
		display: flex;
		align-items: center;

		img {
			margin-left: 8px;
		}
	}

	@media screen and (max-width: 820px) {

		.solutions_wrap {
			padding: 45px 25px 70px;
		}

		h2 {
			padding-bottom: 40px;
			font-size: 32px;
		}

		h3 {
			font-size: 24px;
			padding: 30px 42px 30px 0;
		}

		p,
		a {
			font-size: 16px;
		}

		/* stylelint-disable */
		#acco_button {
			width: 40px;
			height: 40px;
		}

		#accordion {
			border-bottom: 2px solid rgba(112, 112, 112, .5);
		}

		/* stylelint-enable*/
	}

	@media screen and (max-width: 500px) {

		h3 {
			font-size: 20px;
			padding: 25px 37px 25px 0;
		}

		p,
		a {
			font-size: 16px;
		}

		/* stylelint-disable */
		#acco_button {
			width: 30px;
			height: 30px;
		}

		/* stylelint-enable*/
	}
}

#we_integrate {
	overflow: hidden;
	display: flex;
	background: #fff;

	.integrate_wrap {
		white-space: nowrap;
		animation: scroll 13s infinite linear;
		display: inline-block;

		img {
			height: auto;
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 820px) {

		.integrate_wrap {
			animation: scroll 15s infinite linear;

			img {
				max-height: 75px;
			}
		}
	}
}

@keyframes scroll {

	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

/* stylelint-disable */
#lower_home_wrap {
	background: radial-gradient(55.82% 50.73% at 3.88% 78.88%, rgba(13, 132, 103, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), radial-gradient(140.24% 34.97% at 50% -10.93%, rgba(0, 147, 197, 0.06) 0%, rgba(255, 255, 255, 0.00) 59.2%);
}

/* stylelint-enable*/
