#gallery_head {
	max-width: 1000px;
	margin: 0 auto;
	padding: 50px 25px 65px;
	border-bottom: 2px solid #dedede;

	&.blog {

		h1 {
			margin-bottom: 45px;
		}
	}

	h1 {
		font-size: 45px;
		text-align: center;
		color: #1a1c2b;

		span {
			font-weight: 700;
		}
	}

	p {
		padding: 45px 10px;
		line-height: 1.5;
		color: #1a1c2b;
	}

	.btn_group {
		display: flex;
		justify-content: center;
		gap: 25px;
		margin-top: 35px;

		a {
			width: 235px;
		}
	}

	@media screen and (max-width: 1100px) {

		.btn_group {
			margin-top: 0;
			padding: 0 10px;
			flex-wrap: wrap;
			gap: 25px;
		}

		p {
			padding: 45px 0;
		}
	}


	@media screen and (max-width: 820px) {
		padding: 0px 25px 55px;

		h1 {
			text-align: left;
			font-size: 38px;
		}
	}

	@media screen and (max-width: 768px) {

		h1 {
			font-size: 31px;

			&.column {
				display: flex;
				flex-direction: column;
			}
		}
	}

	@media screen and (max-width: 500px) {
		padding: 8px 25px 40px;

		.btn_group {
			flex-direction: column;
			align-items: center;
		}

		p {
			padding: 35px 0;
		}
	}
}
