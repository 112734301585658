#marketing {

	/* stylelint-disable */
	#nav_info {
		overflow: hidden;
	}

	#internal_wrap.marketing {
		justify-content: space-between;
		padding: 0 10px;
		margin: 0;

		.left {
			margin-right: 30px;
		}

		.right {
			margin: 0;
		}
	}
	/* stylelint-enable */
}

#marketing_company_details_wrapper {

	background: #fff;
	max-width: 1560px;
	width: 100%;
	margin: 0 auto 110px;
	display: flex;
	justify-content: space-around;
	box-shadow: 2px 5px 5px rgba(#000, .25);
	padding: 35px 0;
	position: relative;
	z-index: 5;

	.marketing_company_detail {

		display: flex;
		align-items: center;
	}

	.detail {
		display: flex;
		flex-direction: column;
		margin-left: 30px;

		span.top {
			color: $grey;
			font-size: 48px;
			font-weight: 300;
		}

		span {
			font-size: 18px;
		}
	}
}

#websites_integration {

	display: flex;
	flex-direction: column;
	margin-bottom: 110px;

	.title {
		font-weight: 300;
		font-family: $footer_font;
		margin-bottom: 30px;
		text-align: center;
	}

	.integration_list {
		max-width: 1665px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		div {
			margin: 0 10px;
		}
	}
}

.marketing_article {
	display: flex;
	align-items: center;
	max-width: 1500px;
	width: 100%;
	margin: 0 auto 80px;

	&.d_reverse {
		flex-direction: row-reverse;

		.text {

			margin: 0 140px 0 0;
		}
	}

	.image {
		max-width: 593px;
		width: 100%;
	}

	.text {
		margin: 0 0 0 140px;

		h1 {
			font-size: 24px;
			margin-bottom: 10px;
		}

		h2 {
			font-size: 20px;
			margin-bottom: 10px;
		}

		ul {
			list-style-type: disc;

			li {
				margin-left: 40px;
			}
		}
	}
}

@media screen and (max-width: 1045px) {

	#marketing_company_details_wrapper {
		margin: 0 auto 25px;

		.icon img {
			height: 45px;
		}

		.detail {
			margin-left: 15px;

			span {
				font-size: 16px;

				&.top {
					font-size: 40px;
				}
			}
		}
	}

	.marketing_article {

		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 35px;

		&.d_reverse {

			flex-direction: column;
			justify-content: center;

			.text {

				margin: 0;
			}
		}

		.image {
			text-align: center;

			img {
				max-width: 300px;
				margin-bottom: 30px;
			}
		}

		.text {

			margin: 0;
		}
	}
}

@media screen and (max-width: 1020px) {

	#websites_integration {

		margin: 60px 0 110px;
	}
}

@media screen and (max-width: 950px) {

	#marketing_company_details_wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		place-items: center;
	}

	/* stylelint-disable */
	#marketing {

		.marketing_contact {
			display: none;
		}

		#internal_wrap.marketing {
			flex-direction: column;
	
			.left {
				margin: 0;
			}
		}

		#internal_main {

			h3.marketing, h2.tagline_bullets.marketing {
				width: 100%;
			}
		}

		#nav_info article {

			.nav_wrap.marketing {
				padding: 35px;
			}

			h2 {

				&::before {
					display: none;
				}
			}
		}
	}
	/* stylelint-enable */

	#marketing_nav {

		.nav_items {
			justify-content: inherit;
			margin: 0;
			width: 100%;
			height: 69px;

			button {
				white-space: normal;
				min-width: auto;
			}

			.nav_img {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 900px) {

	#marketing {

		article {

			h2.tagline_bullets.marketing {
				line-height: 1.4;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#marketing {

		/*stylelint-disable */
		#nav_info article .title {
			text-align: center;
		}

		#internal_main {

			h3.marketing, h2.tagline_bullets.marketing {
				font-size: 14px;
				text-align: center;
				line-height: 1.1;
			}
		}
		/*stylelint-enable */
	}

	#marketing_company_details_wrapper {
		padding: 20px 0;
		place-items: start;

		.marketing_company_detail {
			margin: 10px 0 10px 60px;
		}

		.icon img {
			height: 45px;
		}

		.detail {

			span {
				font-size: 14px;

				&.top {
					font-size: 30px;
				}
			}
		}
	}

	#websites_integration {

		.integration_list {
			display: grid;
			grid-template-columns: 1fr 1fr;

			div {
				display: flex;
				justify-content: center;
				margin: 15px 0;
				height: 55px;
			}
		}
	}
}

@media screen and (max-width: 550px) {

	#marketing_company_details_wrapper {
		padding: 20px 0;
		place-items: start;

		.detail {

			span {
				font-size: 12px;

				&.top {
					font-size: 28px;
				}
			}
		}

		.marketing_company_detail {
			margin: 10px 0 10px 25px;
		}
	}

	#websites_integration {

		.integration_list {

			div {
				height: 43px;
			}
		}
	}
}