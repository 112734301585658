/* stylelint-disable */
#faqs {
	background: radial-gradient(52.09% 59.93% at -4.82% 61.07%, rgba(13, 132, 103, 0.14) 0%, rgba(103, 164, 205, 0.11) 24.1%, rgba(255, 255, 255, 0.00) 100%);
	;

	.faqs_wrap {
		display: flex;
		padding: 30px 45px 100px 80px;
	}

	@media screen and (max-width: 1250px) {

		.faqs_wrap {
			padding: 15px 30px 65px;
		}
	}

	@media screen and (max-width: 900px) {

		.faqs_wrap {
			flex-direction: column;
			align-items: center;
		}
	}
}

#faq_cta {
	width: 50%;
	flex-grow: 1;

	.faq_cta_wrap {
		width: 100%;
		max-width: 555px;
		box-sizing: border-box;
	}

	h1 {
		font-size: 35px;
		margin-bottom: 34px;

		span {
			font-weight: 700;
		}
	}

	p {
		line-height: 1.4;
		margin-bottom: 45px;
		font-size: 18px;
	}

	h3 {
		color: #052460;
		font-size: 24px;
	}

	.btn_group {
		display: flex;
		margin-top: 35px;
		gap: 20px;

		a {
			width: 169px;
		}
	}

	@media screen and (max-width: 1098px) {

		h1 {
			font-size: 32px;
		}

		p {
			font-size: 16px;
			margin-bottom: 30px
		}

		h3 {
			font-size: 22px;
		}
	}

	@media screen and (max-width: 768px) {

		h1 {

			&.column {
				display: flex;
				flex-direction: column;
			}
		}

		h3 {
			display: none;
		}
	}


	@media screen and (max-width: 900px) {
		width: 100%;
	}

	@media screen and (max-width: 500px) {

		.btn_group {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

#faq_solutions {
	width: 50%;

	.solutions_wrap {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;
		box-sizing: border-box;
	}

	#acco_context {
		display: none;
		flex-direction: column;
		max-width: 835px;
		padding: 10px 32px;
	}

	#accordion {
		position: relative;
		width: 95%;
		margin: 0 auto 25px;
		box-shadow: 4px 4px 10px 0px rgba(5, 36, 96, 0.10);
	}

	#acco_button {
		position: absolute;
		right: 20px;
		top: 36%;
		transform: translate(0%, 0%);
		width: 32px;
		height: 32px;
		background: url('/dist/images/icons/SVG/acco_button.svg');
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;

		&:active,
		&.clicked {
			background: url('/dist/images/icons/SVG/acco_button_close.svg');
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	h3 {
		font-size: 25px;
		font-weight: 600;
		padding: 32px 48px 32px 30px;
		position: relative;
	}

	p {
		padding-bottom: 30px;
		font-size: 18px;
	}

	ul {
		padding-bottom: 30px;
	}

	li {
		font-size: 18px;
		list-style-type: disc;
		margin-left: 25px;
		line-height: 1.6;
	}

	a {
		color: $accent;
		padding-bottom: 25px;
		font-size: 18px;
		display: flex;
		align-items: center;

		img {
			margin-left: 8px;
		}
	}

	@media screen and (max-width: 1098px) {

		h3 {
			font-size: 22px;
		}

		p,
		li {
			font-size: 16px;
		}
	}


	@media screen and (max-width: 900px) {
		width: 100%;
		padding-top: 50px;

		#accordion {
			width: 100%;
		}

		#acco_context {
			padding: 10px 30px;
		}
	}

	@media screen and (max-width: 820px) {

		.solutions_wrap {
			padding: 45px 0 20px;
		}

		h2 {
			padding-bottom: 40px;
		}

		h3 {
			font-size: 24px;
			padding: 30px 42px 30px 18px;
		}

		p,
		a {
			font-size: 16px;
		}

		/* stylelint-enable*/
	}

	@media screen and (max-width: 500px) {

		h3 {
			font-size: 20px;
			padding: 25px 65px 25px 15px;
		}

		p,
		a {
			font-size: 16px;
		}

		/* stylelint-disable */
		#acco_button {
			width: 30px;
			height: 30px;
		}

		/* stylelint-enable*/
	}
}

/***>>>Media Queries***/
