header {
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	background-color: #fff;
	box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, .15);
	z-index: 10;
}

#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, .5);
	z-index: 10;
}

#navbar {
	max-width: 1820px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px 30px;
	box-sizing: border-box;
	z-index: 1;

	.company_cta {
		display: flex;
		align-items: center;
		margin-left: auto;
		text-align: center;

		img {
			margin-top: 3px;
			margin-right: 10px;
		}

		a:first-child {
			margin: 0 30px;
		}
	}

	.menu_toggle {
		display: flex;
		align-items: center;
		margin-left: 90px;

		p {
			color: $primary;
			margin-right: 25px;
		}
	}
}

#has_drop {
	cursor: pointer;
	display: flex;
	align-items: center;
	color: #58595b;

	img {
		padding-left: 10px;
	}

	&:hover {
		color: $accent;
	}
}

#drop_nav {
	display: none;
	position: absolute;
	top: 112px;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	background: #fff;
	padding: 45px 0 20px 35px;
	filter: drop-shadow(4px 4px 10px rgba(5, 36, 96, .10));
	min-height: 410px;
	z-index: 4;

	.menu_line_wrap {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: url("/dist/images/icons/SVG/header_menu_lines.svg");
		background-repeat: no-repeat;
		background-position: right bottom;
		pointer-events: none;
		z-index: -1;
	}

	>li {
		padding: 0 45px;
	}

	li>ul {
		display: flex;
		flex-direction: column;
		padding-left: 105px;
		line-height: 1.7;

		a, span {
			color: #58595b;
			cursor: pointer;

			&:hover {
				color: $accent;
			}
		}

		>ul {
			display: none;
		}
	}

	section {
		display: none;
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		width: 60%;
		background: #f2f9fc;

		.section_wrap {
			position: relative;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			padding: 45px;

			article {
				width: 60%;
				padding: 0 40px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				&:first-of-type {
					border-right: 3px solid rgba(0, 147, 197, .15);
					width: 40%;
					padding: 0;
					justify-content: initial;

					a {
						background: initial;
						font-weight: initial;
						color: #000;
						padding: 5px 0;
						text-align: left;
						max-width: none;
					}
				}
			}

			h2 {
				font-size: 35px;

				span {
					font-weight: 700;
				}
			}

			p {
				padding: 10px 0;
			}

			h3 {
				font-weight: 600;
			}

			a {
				padding: 10px 20px;
				background: $accent;
				color: #fff;
				font-weight: 700;
				text-align: center;
				max-width: 180px;
				border-radius: 8px;
			}
		}

		/* stylelint-disable */
		#section_close {
			position: absolute;
			bottom: 5px;
			right: 20px;
			width: auto;
			height: 25px;
			background: #f2f9fc;
			text-decoration: underline;

			img {
				width: 25px;
				height: 25px;
			}
		}
	}

	button {
		border: none;
		background: #fff;
		font-size: 16px;
		font-weight: 400;
		font-family: $body;
		color: $accent;
		cursor: pointer;
		text-align: left;

		&:hover {
			color: $accent;
		}

		&:focus-within {
			text-decoration: underline;
			font-weight: 700;
		}
	}

	.header_title {
		display: flex;
		align-items: center;

		p {
			font-weight: 700;
			padding-left: 20px;
		}
	}
}

#nav_menu {
	cursor: pointer;
}

/***>>Media Queries***/

@media screen and (max-width: 1750px) {

	#drop_nav {

		>li {
			padding: 0 30px;
		}

		section {
			width: 57%;
		}
	}
}


@media screen and (max-width: 1650px) {

	#drop_nav {

		.header_title {

			img {
				height: 60px;
			}
		}
	}
}

@media screen and (max-width: 1520px) {

	#navbar {

		.menu_toggle {
			margin-left: 30px;
		}

		.logo {
			height: 45px;
		}

		.company_cta {

			.phone {
				white-space: nowrap;
			}
		}
	}

	#drop_nav {
		top: 109px;

		.header_title {

			img {
				height: 55px;
			}
		}

		>li {
			padding: 0 14px;
		}

		li>ul {
			padding-left: 75px;
		}

		section {
			width: 61%;

			.section_wrap {
				padding: 30px;

				h2 {
					font-size: 28px;
				}
			}
		}
	}
}

@media screen and (max-width: 1320px) {

	#drop_nav {

		.header_title {

			p {
				padding-left: 25px;
			}

			img {
				display: none;
			}
		}

		li>ul {
			padding-left: 25px;
		}
	}
}

@media screen and (max-width: 1140px) {

	#navbar {

		.company_cta {

			.address {
				display: none;
			}

			.phone {

				img {
					height: 40px;
				}

				span {
					display: none;
				}
			}
		}
	}

	#drop_nav {
		flex-direction: column;
		padding: 45px 15px;

		.header_title {

			img {
				display: block;
			}

			p {
				padding-left: 20px;
			}
		}

		li>ul {
			padding-left: 75px;
		}

		section {
			width: 67%;
			height: fit-content;
		}
	}
}

@media screen and (max-width: 768px) {

	#nav_menu img {
		height: 30px;
		width: auto;
	}

	#navbar {
		padding: 19px 22px;

		.logo {
			height: 35px;
		}

		.menu_toggle p {
			display: none;
		}

		.company_cta {

			.phone {

				img {
					height: 30px;
				}
			}
		}
	}

	#drop_nav {
		top: 65px;
		padding: 40px 10px;
		min-height: 1040px;

		.header_title {
			width: 300px;
		}

		>li {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		li>ul {
			width: 170px;
			padding-left: 25px;
		}

		section {
			height: 60.8%;
			width: 100%;
			bottom: 0;
			top: unset;

			.section_wrap {
				flex-direction: column;
				align-items: center;

				article {
					width: 345px;
					padding: 45px 0 20px 0;
					flex-grow: 1;

					&:first-of-type {
						border-right: none;
						border-bottom: 3px solid rgba(0, 147, 197, .15);
						width: 345px;
						padding: 0 0 25px 0;
					}
				}
			}

			#section_close {
				top: 5px;
				bottom: unset;
			}
		}
	}
}
